import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import apiList from "../../lib/apiList";
import axios from "axios";
import Cookies from "js-cookie";
import Sidebar from "../../components/sidebar";
import { Audio } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

const TrainerFeedback2 = () => {
  const { state } = useLocation();
  const { employeeId } = state;

  console.log(state);
  const [employeeWorksheet, setEmployeeWorksheet] = useState([]);
  const [trainerFeedback, setTrainerFeedback] = useState([]);
  const [loading, setLoading] = useState(true);

  const review = async () => {
    const token = Cookies.get("token");
    try {
      const api = `${apiList.indEmployeWorkSheet}/${employeeId}`;
      const response = await axios.get(api, {
        headers: {
          token: token,
        },
      });
      setEmployeeWorksheet(response.data);
      setTrainerFeedback(response.data.weeklyFeedBackByTrainer);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  console.log(employeeWorksheet);
  console.log(trainerFeedback);

  const navigate = useNavigate();

  
  const GotoFeedback = () => {
    navigate("/updateFeedback", { state: { employeeId } });
  };

  useEffect(() => {
    review();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3" style={{ backgroundColor: "#733272" }}>
            <Sidebar />
          </div>

          <div className="col-md-9" style={{ height: "100vh", overflowY: "scroll", paddingTop: "25px" }}>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center mt-5">
                <Audio type="Audio" color="#6a2a69" height={50} width={60} />
                
              </div>
            ) : (
              <div>
                <h3 style={{ color: "#6a2a69" }}>Employee Name: <span>{employeeWorksheet.employeeName}</span></h3>
                <h5>Employee ID: <span>{employeeWorksheet.employeeId}</span></h5>

                <div>
                  {trainerFeedback.length === 0 ? (
                    <div className="text-center">
                      <img src="./images/no_feedback.png" alt="no feedback" style={{ width: "500px" }} />
                    </div>
                  ) : (
                    <div>
                      {trainerFeedback.map((feedback) => (
                        <div className="card mt-3 pl-3" key={feedback.startDate}>
                          <h5>{feedback.startDate} to {feedback.endDate}</h5>
                          <p className="mb-2"><b>Course Name / Task Name </b>: {feedback.courseName}</p>
                          <p className="mb-2"><b>Task Status / Work Status</b>: {feedback.taskStatus}</p>
                          <p className="mb-2">
                            <b>Week Performance </b>: {feedback.feedback}
                          </p>
                        </div>
                      ))}
                      <button className='back_button mt-4' onClick={GotoFeedback}>HR </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerFeedback2;

import React, { useState, useEffect } from "react";
import TrainerSidebar from "../TrainerSidebar";
import { IoIosArrowBack } from 'react-icons/io';
import { useLocation } from "react-router-dom";
import apiList from "../../lib/apiList";
import axios from "axios";
import Cookies from "js-cookie";
import { Audio } from 'react-loader-spinner';

const TrainerFeedback1 = () => {
  const { state } = useLocation();
  const { employeeId } = state;

  const onBackToTrainer = () => {
    window.location.href = "/trainer";
  }

  const [loading, setLoading] = useState(true);
  const [employeeWorksheet, setEmployeeWorksheet] = useState([]);
  const [trainerFeedback, setTrainerFeedback] = useState([]);

  const review = async () => {
    const token = Cookies.get("token");
    try {
      const api = `${apiList.indEmployeWorkSheet}/${employeeId}`;
      const response = await axios.get(api, {
        headers: {
          token: token,
        },
      });
      setEmployeeWorksheet(response.data);
      setTrainerFeedback(response.data.weeklyFeedBackByTrainer);
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      setLoading(false); // Set loading to false on error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    review();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3" style={{ backgroundColor: "#733272" }}>
            <TrainerSidebar />
          </div>
          <div className="col-md-9" style={{ height: "100vh", overflowY: "scroll", paddingTop: "25px" }}>
            <button className="back-arrow" onClick={onBackToTrainer}>
              <IoIosArrowBack />
            </button>
            <h3 style={{ color: "#6a2a69" }}>Employee Name: <span>{employeeWorksheet.employeeName}</span></h3>
            <h5>Employee ID: <span>{employeeWorksheet.employeeId}</span></h5>
            <div>
              {loading ? (
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <Audio
                    type="Audio"
                    color="#6a2a69"
                    height={50}
                    width={60}
                  />
                 
                </div>
              ) : (
                <>
                  {trainerFeedback.length === 0 ? (
                    <div className="text-center"><img src="./images/no_feedback.png" alt="no  feedback" style={{ height: "400px" }}></img></div>
                  ) : (
                    trainerFeedback.map((feedbacks => (
                      <div className="card mt-3 pl-3" key={feedbacks.startDate}>
                        <h5>{feedbacks.startDate} to {feedbacks.endDate}</h5>
                        <p className="mb-2"><b>Course Name / Task Name </b>: {feedbacks.courseName}</p>
                        <p className="mb-2"><b>Task Status / Work Status </b>: {feedbacks.taskStatus}</p>
                        <p className="mb-2">
                          <b>Week Performance </b>: {feedbacks.feedback}
                        </p>
                      </div>
                    )))
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerFeedback1;

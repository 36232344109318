import React from 'react'
import Sidebar from './sidebar'

function Changetrainer() {
  return (
    <div className='container-fluid'>

      <div className='row'>
        <div className='col-md-3 sidebar_bg'>
          <Sidebar />
        </div>
        <div className='col-md-8 table_item'>
          <div className='admin_header my-2'>Change Trainer Dashboard</div>
          <div className='adimn_dashnbord  my-3'>Welcome to the Perfex Admin Dashboard. Please Add your Employement.</div>
          <div>
            <input type='date' className='w-25 p-1 date_item' />
          </div>
          <div className='row'>
            <div className='col-lg-6 col-md-6'>
              <div className='my-2'>
                <label for="names">Previous Trainer</label>
                <div>
                  <select className='w-100 select_item' >
                    <option className='name_item1' value="Ashok">Ashok</option>
                    <option className='name_item' value="Ganesh">Ganesh</option>
                    <option className='name_item' value="Ajay">Ajay</option>
                    <option className='name_item' value="Kalyan">Kalyan</option>
                    <option className='name_item' value="Ramya">Ramya</option>
                    <option className='name_item' value="Shashi">Shashi</option>
                  </select>
                </div>
              </div>
            </div>

            <div className='col-lg-6 col-md-6'>

              <div className='my-2'>
                <label>Current Trainer</label>
                <div>
                  <select className='w-100 select_item'>
                    <option className='name_item' value="Ashok">Ashok</option>
                    <option className='name_item' value="Ganesh">Ganesh</option>
                    <option className='name_item' value="Ajay">Ajay</option>
                    <option className='name_item' value="Kalyan">Kalyan</option>
                    <option className='name_item' value="Ramya">Ramya</option>
                    <option className='name_item' value="Shashi">Shashi</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className='employement_button my-3'>
            <button className='employement_item'>Change Trainer</button>
          </div>
        </div>
        <div className='col-md-1'></div>
      </div>

    </div>
  )
}

export default Changetrainer
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import apiList from "../lib/apiList";
import axios from "axios";
import Cookies from "js-cookie";
import Sidebar from "./sidebar";
import { Audio } from 'react-loader-spinner';

const WorkingHours = () => {
  const { state } = useLocation();
  const { employeeId } = state;

  console.log(state);
  const [employeeWorksheet, setEmployeeWorksheet] = useState([]);
  const [workingHours, setWorkingHours] = useState([]);
  const [loading, setLoading] = useState(true);

  const review = async () => {
    const token = Cookies.get("token");
    try {
      const api = `${apiList.indEmployeWorkSheet}/${employeeId}`;
      const response = await axios.get(api, {
        headers: {
          token: token,
        },
      });
      setEmployeeWorksheet(response.data);
      setWorkingHours(response.data.workingHours);
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      setLoading(false); // Set loading to false on error
      console.error("Error fetching data:", error);
    }
  };

  console.log(employeeWorksheet);
  console.log(workingHours);

  useEffect(() => {
    review();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3" style={{ backgroundColor: "#733272" }}>
            <Sidebar />
          </div>

          <div className="col-md-9 " style={{ height: "100vh", overflowY: "scroll", paddingTop: "25px" }}>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center mt-5">
                <Audio
                  type="Audio"
                  color="#6a2a69"
                  height={50}
                  width={60}
                />
                
              </div>
            ) : (
              <div>
                <h3 style={{ color: "#6a2a69" }}>Employee Name: <span>{employeeWorksheet.employeeName}</span></h3>
                <h5>Employee ID: <span>{employeeWorksheet.employeeId}</span></h5>

                <div>
                  {workingHours.length === 0 ? (
                    <div style={{ fontSize: "18px", fontWeight: "500", paddingTop: "40px", textAlign: "center" }}>No report on working hours</div>
                  ) : (
                    workingHours.map((working, index) => (
                      <div className="card mt-3" key={index}>
                        <h5>Date :  {working.date}</h5>
                        <p className="mb-1"><b>Punch In Time</b>: {working.punchInTime}</p>
                        <p className="mb-1"><b>Punch Out Time </b>: {working.punchOutTime}</p>
                        <p className="mb-1">
                          <b>Duration for day  </b>: {working.duration} Hrs
                        </p>
                      </div>
                    ))
                  )}

                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkingHours;

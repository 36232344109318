import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TrainerSidebar from './TrainerSidebar';
import apiList from '../lib/apiList';
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";


function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Month is 0-indexed, so add 1
  const year = date.getFullYear();

  // Use template literals to format the date in "DD-MM-YYYY" format
  return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
}

function Trainerholiday() {

  const [holidays, setHolidays] = useState([]);
  const [showAllHolidays, setShowAllHolidays] = useState(false);

  const [currentDateHolidayWish, setCurrentDateHolidayWish] = useState('');


  useEffect(() => {
    // Load holidays from the backend when the component mounts.
    loadHolidays();
  }, []);

  const loadHolidays = async () => {
    try {
      const response = await axios.get(`${apiList.postHoliday}`);
      console.log('Holidays from server:', response.data);
      const sortedHolidays = response.data.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });
      setHolidays(sortedHolidays);
    } catch (error) {
      console.error('Error loading holidays:', error);
    }
  };




  const isHoliday = (date) => {
    return holidays.some((holiday) => {
      const formattedHolidayDate = formatDate(holiday.date);
      return formattedHolidayDate === date;
    });
  };

  const today = new Date();
  const formattedToday = formatDate(today);
  const isTodayHoliday = isHoliday(formattedToday);

  const nearestHoliday = holidays
    .filter((holiday) => new Date(holiday.date) >= today)
    .sort((a, b) => new Date(a.date) - new Date(b.date))[0];

  const todayHoliday = holidays.find((holiday) => formatDate(holiday.date) === formattedToday);

  const redTextStyle = {
    color: 'red',
    fontWeight: '700',
    fontFamily: 'sans-serif',
    fontSize: '22px',
  };

  useEffect(() => {
    // Check if the current date is a holiday and has a holiday wish
    if (isTodayHoliday && todayHoliday) {
      setCurrentDateHolidayWish(todayHoliday.holidayWish);
    } else {
      // Reset the holiday wish if it's not a holiday
      setCurrentDateHolidayWish('');
    }
  }, [isTodayHoliday, todayHoliday]);

  const [punchInTime, setPunchInTime] = useState(null);
  const [running, setRunning] = useState(null);
  const [duration, setDuration] = useState(null);
  const [time, setTime] = useState(null);
  const [totalDuration, setTotalDuration] = useState(null);
  const [previousDuration, setPreviousDuration] = useState([]);
  const [punchOutTime, setPunchOutTime] = useState(null);

  const updateEmployeeFeedback = async () => {

    const currentDate = new Date();
const day = String(currentDate.getDate()).padStart(2, '0');
const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
const year = currentDate.getFullYear();
const formattedDate = `${day}-${month}-${year}`;




    const existingRecord = previousDuration.find((record) => record.date === formattedDate);

    Cookies.get("punchInTime");
    setPunchOutTime(new Date());
    const punchIn = new Date(punchInTime).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const punchOut = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    console.log(punchIn);
    console.log(punchOut);

    

    const timeDifference = new Date() - new Date(Cookies.get("punchInTime"));
    const hours = String(Math.floor(timeDifference / 3600000)).padStart(2, "0");
    const minutes = String(
      Math.floor((timeDifference % 3600000) / 60000)
    ).padStart(2, "0");
    const seconds = String(
      Math.floor((timeDifference % 60000) / 1000)
    ).padStart(2, "0");

    const formattedDuration = `${hours}:${minutes}:${seconds}`;
    setTotalDuration(formattedDuration);
    Cookies.remove("punchInTime");
    setPunchInTime(null);

    const token = Cookies.get("token");
    const api = `${apiList.workingDuration}`;
    try {

      if (existingRecord) {
        // If a record already exists, display an toast.error or take appropriate action
        toast.error("A record for today already exists.");
      }
      else {
        const currentDate = new Date();
const day = String(currentDate.getDate()).padStart(2, '0');
const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
const year = currentDate.getFullYear();
const formattedDate = `${day}-${month}-${year}`;

        const response = await axios.put(
          api,
          {
            workingHours: [...previousDuration, {
              date: formattedDate,
              punchInTime: punchIn,
              punchOutTime: punchOut,
              duration: formattedDuration

            }]
          },
          {
            headers: {
              token: token,
            },
          }
        );

        console.log("Feedback updated successfully:", response.data);

        toast("Punched Out successfully", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          className: "custom-toast-custom",
        });
      }

    } catch (error) {
      console.error("Error updating feedback:", error);
    }

  };

  const handlePunchIn = () => {

    const currentDate = new Date();
const day = String(currentDate.getDate()).padStart(2, '0');
const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
const year = currentDate.getFullYear();
const formattedDate = `${day}-${month}-${year}`;




    const existingRecord = previousDuration.find((record) => record.date === formattedDate);

    if (existingRecord) {
      // If a record already exists, display an toast.error or take appropriate action
      toast.error("A record for today already exists. You cannot update it.");
    }
    else {
      const punchInTimestamp = new Date();
      setTime(new Date());
      const expirationTime = new Date(punchInTimestamp.getTime() + 10 * 60 * 60 * 1000); // Add 2 minutes to the current time
      setPunchInTime(punchInTimestamp);
      Cookies.set("punchInTime", punchInTimestamp, { expires: expirationTime });

      toast("Punch In successful", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: "custom-toast-custom"
      });
    }


  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRunning(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const punchInTime = Cookies.get("punchInTime");
    if (punchInTime) {
      setPunchInTime(punchInTime);
    }
  }, []);

  useEffect(() => {

    if (punchInTime) {
      const timeDifference = running - new Date((Cookies.get("punchInTime")));
      const hours = String(Math.floor(timeDifference / 3600000)).padStart(2, '0');
      const minutes = String(Math.floor((timeDifference % 3600000) / 60000)).padStart(2, '0');
      const seconds = String(Math.floor((timeDifference % 60000) / 1000)).padStart(2, '0');
      const formattedDuration = `${hours}:${minutes}:${seconds}`;
      setDuration(formattedDuration);
    }

  }, [running, punchInTime]);


  const fetchEmployeeDuration = async (req, res) => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(`${apiList.worksheetProfile}`, {
        headers: {
          token: token,
        },
      });
      setPreviousDuration(response.data.workingHours);
    } catch (error) { }
  };

  useEffect(() => {
    fetchEmployeeDuration();
  }, [previousDuration]);


  useEffect(() => {
    const punchInTime = Cookies.get("punchInTime");
    if (punchInTime) {
      setPunchInTime(punchInTime);
    }
  }, []);

  console.log(previousDuration);
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-3 sidebar_bg' style={{ height: "100vh" }}>
          <TrainerSidebar />
          <ToastContainer />
        </div>
        <div className='col-md-9' style={{ height: "100vh", overflowY: "scroll" }}>
          <div className=" d-flex justify-content-between" style={{ paddingTop: "25px" }}>
            <button className=" submit-button1" onClick={updateEmployeeFeedback}>
              Punch Out{" "}
            </button>
            <button
              className=" submit-button1"
              onClick={handlePunchIn}
              disabled={punchInTime !== null}
            >
              {" "}
              Punch In{" "}
            </button>
          </div>
          <div className="d-flex justify-content-end">
            <p className="mt-2 note_para" >
              Note :Punch out is mandatory at the end of day and once punched
              out your working duration for the day will be closed
            </p>
          </div>
          <div className="d-flex justify-content-end">
            {punchInTime === null ? (
              <h6></h6>
            ) : (
              <h5>
                Punched In time :{" "}
                <span>
                  {punchInTime
                    ? new Date(punchInTime).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: true,
                    })
                    : ""}
                </span>{" "}
              </h5>
            )}
          </div>
          <div className="d-flex justify-content-end ">
            {punchInTime === null ? (
              <h6></h6>
            ) : (
              <p className="m-0">{duration}</p>
            )}
          </div>
          <div className='banner_parrent'>
            <div className='holiday_banner'>
              <center>
                <h2 className='pt-4 holiday_head'>
                  <em style={{ color: "#6a2a69" }}>Holidays </em>
                </h2>
                {showAllHolidays ? (
                  <div style={{ height: "150px", overflowY: "scroll", width: "700px", marginTop: "25px" }}>
                    <table className=" text-center table-bordered  table-hover w-100  m-auto" >
                      <thead className='table_heading sticky-top ' >
                        <tr style={{ color: "#fff" }}>
                          <th className='p-2'>Date</th>
                          <th className='p-2'>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {holidays.map((holiday) => (
                          <tr key={holiday._id}>
                            <td className='p-1' style={{ fontSize: "18px", fontWeight: "400" }}>{formatDate(holiday.date)}</td>
                            <td className='p-1' style={{ fontSize: "18px", fontWeight: "400" }}>{holiday.holidayDes}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <>
                    {isTodayHoliday ? (
                      <div>
                        <em className='holidayWishes holidaysss'>
                          {formattedToday} - {todayHoliday ? todayHoliday.holidayDes : 'No description'} (Today is a holiday!)
                        </em>
                      </div>
                    ) : (
                      nearestHoliday && (
                        <div>
                          <em
                            style={{ fontSize: "23px", color: "#fff" }}
                            className="holidaysss"
                          >
                            Upcoming Holiday : {formatDate(nearestHoliday.date)} -{" "}
                            {nearestHoliday.holidayDes}
                          </em>
                        </div>
                      )
                    )}
                  </>
                )}

                {isTodayHoliday && currentDateHolidayWish && (
                  <div className=' p-3'>
                    <em className='holidayWishes'>{currentDateHolidayWish}</em>
                  </div>
                )}

                {!showAllHolidays ? (
                  <button onClick={() => setShowAllHolidays(true)} className='p-2 mb-3 show_holiday mt-2'>
                    View More Holidays
                  </button>
                ) : (
                  <button onClick={() => setShowAllHolidays(false)} className='p-2 mb-3 show_holiday mt-2'>
                    Hide Holidays
                  </button>
                )}

              </center>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Trainerholiday

import React from 'react'
import { useNavigate, NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import apiList from '../lib/apiList';
import axios from 'axios';
import Cookies from 'js-cookie';

function Sidebar() {
  const [loginTime, setLoginTime] = useState(new Date());
  const [loggedInTime, setLoggedInTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loginEmployee, setLoginEmployee] = useState([]);


  const navigate = useNavigate();

  const GotoAllemployee = () => {
    navigate("/allEmploye");
  };

  const GotoFeedback = () => {
    navigate("/Feedback");
  };
  const GotoSidebar = () => {
    navigate("/admin");
  };

  const Logout = () => {
    // localStorage.removeItem("token")
    Cookies.remove("token")
    localStorage.removeItem('employeType')
    localStorage.removeItem('loggedinTime')
    navigate('/')
  }

  useEffect(() => {
    let token = Cookies.get('token')
    if (token === undefined) {
      navigate('/')
    }

    if (loggedInTime) {
      // Calculate the duration in milliseconds
      const timeDifference = loginTime - new Date(loggedInTime);

      // Convert the duration to a human-readable format (e.g., HH:mm:ss)
      const hours = String(Math.floor(timeDifference / 3600000)).padStart(2, '0');
      const minutes = String(Math.floor((timeDifference % 3600000) / 60000)).padStart(2, '0');
      const seconds = String(Math.floor((timeDifference % 60000) / 1000)).padStart(2, '0');

      const formattedDuration = `${hours}:${minutes}:${seconds}`;
      setDuration(formattedDuration);
    }

  }, [loginTime, loggedInTime]);

  const fetchEmployee = async (req, res) => {
    try {
      const token = Cookies.get("token");
      const loggedInTime = localStorage.getItem("loggedinTime");
      setLoggedInTime(loggedInTime)

      const response = await axios.get(`${apiList.profile}`, {
        headers: {
          token: token,
        },
      });
      setLoginEmployee(response.data);
    } catch (error) { }
  };

  useEffect(() => {
    fetchEmployee();
  }, []);


  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoginTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  console.log(loginEmployee)


  return (
    <div className='container-fluid p-0'>
      <div className='sidebar_employee  '>
        <div className="sidebar_item  d-lg-block d-xl-block d-none " >
          <div className='admin_section '>
            <div className='image_section text-center py-3 '>
              <img src="./images/Perfexlogowhite.png" className='logo_img' alt='logo' />
            </div>
            <div className=" ">
              <div className="admin_name py-1">
                <span>Admin Name : </span><em>{loginEmployee.employeeName}</em>
              </div>
              <div className="admin_name py-1">
                <span> ID : </span><em>{loginEmployee.employeeId}</em>
              </div>
              <div className="admin_name py-1">
                <span>Designation : </span> <em>{loginEmployee.designation}</em>
              </div>
              {/* <div className="admin_id text-end ">
                Time : <span>{loginTime.toLocaleTimeString()}</span>

                  </div> */}
              <div className="admin_name py-1 ">
                <span> Logged in Time : </span> <em>{loggedInTime ? new Date(loggedInTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }) : ''}</em>
              </div>
              <div className='py-1 admin_name'>
                <span> Duration : </span> <em>{duration}</em>
              </div>
            </div>
          </div>
          <div className='sidebar '>
            <div className=''>
              <div className='mt-4'><NavLink to="/holiday" className=' my-3  employement ' ><i class="fa-solid fa-person-walking-luggage   employe_icon"></i> Update Holiday</NavLink> </div>
              <div className='mt-3'><NavLink to="/admin" className=' my-3  employement ' ><i class="ri-user-add-line  employe_icon"></i> Add Employee</NavLink> </div>
              <div className='mt-4'><NavLink to="/allEmploye" className='employement my-3 ' ><i class="ri-team-fill  employe_icon"></i> All Employees</NavLink></div>
              <div className='mt-4' ><NavLink to="/Feedback" className='employement my-3  ' > <i class="ri-feedback-line employe_icon"></i> FeedBack</NavLink></div>
              {/* <div className='my-4' ><NavLink to="/Changetrainer" className='employement my-3 ' > <i class="ri-user-shared-line employe_icon"></i> Assign (OR) Change Trainer </NavLink></div> */}
            </div>
          </div>
          {/* <div className='px-2 mt-3'>
                  <div className='employement my-3 mx-2 active ' onClick={GotoSidebar}><i class="ri-user-add-line  employe_icon"></i> Add Employee</div>
                  <div className='employement my-3 mx-2 ' onClick={GotoAllemployee}><i class="ri-team-fill  employe_icon"></i> All Employees</div>
                  <div className='employement my-3 mx-2 ' onClick={GotoFeedback}> <i class="ri-feedback-line employe_icon"></i> FeedBack</div>
                </div> */}
          <div className='button_item '>
            <button className='logout_button' onClick={Logout}> Logout <i className="fa-solid fa-arrow-right-from-bracket mx-2"></i></button>
          </div>

        </div>
      </div>
    </div>

  )
}

export default Sidebar

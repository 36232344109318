import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiList from '../lib/apiList';

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is 0-indexed, so add 1
    const year = date.getFullYear();

    // Use template literals to format the date in "DD-MM-YYYY" format
    return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
}

function Viewholiday() {

    const [holidays, setHolidays] = useState([]);
    const [showAllHolidays, setShowAllHolidays] = useState(false);


    useEffect(() => {
        // Load holidays from the backend when the component mounts.
        loadHolidays();
    }, []);

    const loadHolidays = async () => {
        try {
            const response = await axios.get(`${apiList.postHoliday}`);
            console.log('Holidays from server:', response.data);
            const sortedHolidays = response.data.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateA - dateB;
            });
            setHolidays(sortedHolidays);
        } catch (error) {
            console.error('Error loading holidays:', error);
        }
    };

    const isHoliday = (date) => {
        return holidays.some((holiday) => {
            const formattedHolidayDate = formatDate(holiday.date);
            return formattedHolidayDate === date;
        });
    };

    const today = new Date();
    const formattedToday = formatDate(today);
    const isTodayHoliday = isHoliday(formattedToday);

    const nearestHoliday = holidays
        .filter((holiday) => new Date(holiday.date) >= today)
        .sort((a, b) => new Date(a.date) - new Date(b.date))[0];

    const todayHoliday = holidays.find((holiday) => formatDate(holiday.date) === formattedToday);

    return (
        <div>
            <div className="calendar m-2">
                <h2>Holidays:</h2>

                <ul style={{ listStyle: 'none' }}>
                    {showAllHolidays ? (
                        holidays.map((holiday) => (
                            <li key={holiday._id} className={isHoliday(formatDate(holiday.date)) ? 'red-date' : ''}>
                                {formatDate(holiday.date)} - {holiday.holidayDes}
                            </li>
                        ))
                    ) : (
                        <>
                            {isTodayHoliday ? (
                                <li>
                                    {formattedToday} - {todayHoliday ? todayHoliday.holidayDes : 'No description'} (Today is a holiday!)
                                </li>
                            ) : (
                                nearestHoliday && (
                                    <li>
                                        Nearest Holiday: {formatDate(nearestHoliday.date)} - {nearestHoliday.holidayDes}
                                    </li>
                                )
                            )}
                        </>
                    )}
                </ul>
                {!showAllHolidays ? (
                    <button onClick={() => setShowAllHolidays(true)} className='p-2'>
                        View All Holidays
                    </button>
                ) : (
                    <button onClick={() => setShowAllHolidays(false)} className='p-2'>
                        Hide Holidays
                    </button>
                )}
            </div>
        </div>
    )
}

export default Viewholiday
import { Routes, Route } from 'react-router-dom';
import Login from './components/login';
import Admin from './components/Admin';
import AllEmployee from './components/AllEmploye';
import EmployeFeedback from './components/EmployeFeedback';
import FeedBack from './components/Feedback';
import UpdateFeedback from './components/updateFeedback';
import Sidebar from './components/sidebar';
import Adminsidebar from './EmployeeComponent/Adminsidebar';
import Trainee from './EmployeeComponent/Trainee';
import Allworksheet from './EmployeeComponent/Allworksheet';
import AdminFeedback from './EmployeeComponent/AdminFeedback';
// import TrainerSidebar from './Trainer/TrainerSidebar';
import Trainer from './Trainer/Trainer'
import TrinerReview from './Trainer/TrinerReview'
import TraineesList from './Trainer/TraineesFeedback/TraineesList'
import TraineeStatus from './Trainer/TraineesFeedback/TraineeStatus'
import TrinessList1 from './Trainer/AllFeedback/TrinessList1'
import TrainerFeedback1 from './Trainer/AllFeedback/TrainerFeedback1'

import Changetrainer from './components/Changetrainer';
import Forgotpassword from './components/Forgotpassword';
import Logintest from './components/logintest';

import Holiday from './components/holidays';
import Viewholiday from './components/viewholiday';
import TrainerFeedback2 from './Trainer/AllFeedback/hrviewingTrainerFeedback';
import WorkingHours from './components/workingHours';
import Traineeholiday from './EmployeeComponent/traineeholiday';
import Trainerholiday from './Trainer/Trainerholiday';
import TrainerSidebar from './Trainer/TrainerSidebar';


function App() {
  return (
    <>

      <div className='bodyy bodyyy'>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/sidebar" element={<Sidebar />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/allEmploye" element={<AllEmployee />} />
          <Route exact path="/employeFeedback" element={<EmployeFeedback />} />
          <Route exact path="/Feedback" element={<FeedBack />} />
          <Route exact path="/updateFeedback" element={<UpdateFeedback />} />
          <Route exact path="/Adminsidebar" element={<Adminsidebar />} />
          <Route exact path="/trainee" element={<Trainee />} />
          <Route exact path="/Allworksheet" element={<Allworksheet />} />
          <Route exact path="/Adminfeedback" element={<AdminFeedback />} />
          <Route exact path='/trainer' element={<Trainer />} />
          <Route exact path='/trainerreview' element={<TrinerReview />} />
          <Route exact path='/traineeslist' element={<TraineesList />} />
          <Route exact path='/traineestatus' element={<TraineeStatus />} />
          <Route exact path='/trineelist1' element={<TrinessList1 />} />
          <Route exact path='/trainerfeedback1' element={<TrainerFeedback1 />} />
          <Route exact path='/trainersidebar' element={<TrainerSidebar />} />
          <Route exact path="/Changetrainer" element={<Changetrainer />} />
          <Route exact path="/Forgotpassword" element={<Forgotpassword />} />
          <Route exact path="/logintest" element={<Logintest />} />
          <Route exact path='/hrviewingtrainerfeedback' element={<TrainerFeedback2 />} />
          <Route exact path="/employeesworkinghours" element={<WorkingHours />} />
          <Route exact path='/holiday' element={<Holiday />} />
          <Route exact path='/view' element={<Viewholiday />} />
          <Route exact path='/Traineeholiday' element={<Traineeholiday />} />
          <Route exact path='/Trainerholiday' element={<Trainerholiday />} />

        </Routes>
      </div>

      <div className="bodyyyy">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="desktop_img">
              <img src="./images/desktop only.png" className=" img-fluid" alt="desktop"/>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </>

  )
}

export default App;





import React, { useState, useEffect } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import apiList from "../lib/apiList";
import Cookies from "js-cookie";

const TrainerSidebar = () => {


  let navigate = useNavigate();

  const [tabStatus, setTabStatus] = useState('Trainees')

  const [loginTime, setLoginTime] = useState(new Date());
  const [loggedInTime, setLoggedInTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loginEmployee, setLoginEmployee] = useState([]);

  useEffect(() => {
    let token = Cookies.get('token')
    if (token === null) {
      navigate('/')
    }

    if (loggedInTime) {
      // Calculate the duration in milliseconds
      const timeDifference = loginTime - new Date(loggedInTime);

      // Convert the duration to a human-readable format (e.g., HH:mm:ss)
      const hours = String(Math.floor(timeDifference / 3600000)).padStart(2, '0');
      const minutes = String(Math.floor((timeDifference % 3600000) / 60000)).padStart(2, '0');
      const seconds = String(Math.floor((timeDifference % 60000) / 1000)).padStart(2, '0');

      const formattedDuration = `${hours}:${minutes}:${seconds}`;
      setDuration(formattedDuration);
    }

  }, [loginTime, loggedInTime]);


  const Logout = () => {
    Cookies.remove("token");
    localStorage.removeItem("employeType");
    localStorage.removeItem('loggedinTime')
    navigate("/");
  };

  const onActiveTrainees = () => {
    setTabStatus("Trainees")
    navigate("/trainer")

  };

  const onActiveFeedback = () => {
    setTabStatus("Feedback")
    navigate("/traineeslist")

  };

  const onActiveAllEmpl = () => {
    setTabStatus("AllEmp")
    // navigate("/trineelist1")
  };


  const fetchEmployee = async (req, res) => {
    try {
      const token = Cookies.get("token");
      const loggedInTime = localStorage.getItem("loggedinTime");
      setLoggedInTime(loggedInTime)

      const response = await axios.get(`${apiList.profile}`, {
        headers: {
          token: token,
        },
      });
      setLoginEmployee(response.data);
    } catch (error) { }
  };

  useEffect(() => {
    fetchEmployee();
  }, []);


  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoginTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);



  return (
    <div>
      <div className="container-fluid p-0">
        <div className="sidebar_item  d-lg-block d-xl-block d-none  sticky-top">
          <div className="admin_section ">
            <div className="image_section text-center py-3 ">
              <img
                src="./images/Perfexlogowhite.png"
                className="logo_img img-fluid"
                alt="logo"
              />
            </div>
            <div className=" mb-3 employe_detail ">
              <div className="admin_name mb-1">
                <span> Trainer Name : </span><em>{loginEmployee.employeeName}</em>
              </div>
              <div className="admin_name mb-1">
                <span>  ID : </span> <em>{loginEmployee.employeeId}</em>
              </div>
              <div className="admin_name mb-1">
                <span>Designation : </span><em>{loginEmployee.designation}</em>
              </div>
              {/* <div className="admin_id text-end ">
                    Time : <span>{loginTime.toLocaleTimeString()}</span>

                  </div> */}
              <div className="admin_name mb-1 ">
                <span>Logged in Time : </span><em>{loggedInTime ? new Date(loggedInTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }) : ''}</em>
              </div>
              <div>
                {/* duration : <span>{duration}</span> */}
              </div>
            </div>
          </div>
          <div className="sidebar ">
            <div className='mt-4'>
              <div className='mt-4'><NavLink to="/Trainerholiday" className=' my-3  employement' ><i class="ri-home-8-line  employe_icon"></i> Home</NavLink> </div>
              <div className='mt-4'><NavLink to="/trainer" className=' my-3  employement' ><i class="ri-user-3-line  employe_icon"></i> Trainee's</NavLink> </div>
              <div className='mt-4'><NavLink to="/traineeslist" className='employement my-3  ' ><i class="ri-team-fill  employe_icon"></i> Trainee's Feedback</NavLink></div>

            </div>
          </div>
          <div className="button_item ">
            <button className="logout_button" onClick={Logout}>
              {" "}
              Logout <i class="fa-solid fa-arrow-right-from-bracket pl-2"></i>
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default TrainerSidebar;

import React from 'react'
import { useNavigate, NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import apiList from '../lib/apiList';
import Cookies from 'js-cookie';


function Adminsidebar() {

  const navigate = useNavigate();
  const [loginTime, setLoginTime] = useState(new Date());
  const [loggedInTime, setLoggedInTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loginEmployee, setLoginEmployee] = useState([]);
  const [tabStatus, setTabStatus] = useState('Trainees')



  useEffect(() => {
    let token = Cookies.get('token')
    if (token === undefined) {
      navigate('/')
    }

    if (loggedInTime) {
      // Calculate the duration in milliseconds
      const timeDifference = loginTime - new Date(loggedInTime);

      // Convert the duration to a human-readable format (e.g., HH:mm:ss)
      const hours = String(Math.floor(timeDifference / 3600000)).padStart(2, '0');
      const minutes = String(Math.floor((timeDifference % 3600000) / 60000)).padStart(2, '0');
      const seconds = String(Math.floor((timeDifference % 60000) / 1000)).padStart(2, '0');

      const formattedDuration = `${hours}:${minutes}:${seconds}`;
      setDuration(formattedDuration);
    }

  }, [loginTime, loggedInTime]);

  const GotoWorksheet = () => {
    navigate("/trainee");
  };
  const GotoAllWorksheet = () => {
    navigate("/Allworksheet");
  };
  const GotoAdminfeedback = () => {
    navigate("/Adminfeedback");
  };

  const Logout = () => {
    Cookies.remove("token");
    localStorage.removeItem('employeType');
    localStorage.removeItem('loggedinTime')
    navigate('/')
  }

  useEffect(() => {
    let token = Cookies.get('token')
    console.log(token, 'token')
    if (token === null) {
      navigate('/')
    }

  })

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoginTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);


  const fetchEmployee = async (req, res) => {
    try {
      const token = Cookies.get("token");
      const loggedInTime = localStorage.getItem("loggedinTime");
      setLoggedInTime(loggedInTime)

      const response = await axios.get(`${apiList.profile}`, {
        headers: {
          token: token,
        },
      });
      setLoginEmployee(response.data);
    } catch (error) { }
  };

  useEffect(() => {
    fetchEmployee();
  }, []);



  return (
    <div className='container-fluid p-0'>
      <div className='sidebar_employee  sticky-top '>
        <div className="sidebar_item  d-lg-block d-xl-block d-none sticky-top" >
          <div className='admin_section '>
            <div className='image_section text-center py-3 '>
              <img src="./images/Perfexlogowhite.png" className='logo_img' alt='logo' />
            </div>
            <div className=" mb-3  ">
              <div className="admin_name mb-1">
                <spn>Employee Name : </spn><em>{loginEmployee.employeeName}</em>
              </div>
              <div className="admin_name mb-1">
                <span> ID : </span> <em>{loginEmployee.employeeId}</em>
              </div>
              <div className="admin_name mb-1">
                <span>  Designation : </span> <em>{loginEmployee.designation}</em>
              </div>
              {/* <div className="admin_id text-end ">
                    Time : <span>{loginTime.toLocaleTimeString()}</span>

                  </div> */}
              <div className="admin_name text-end mb-1 ">
                <span>Logged in Time :</span><em> {loggedInTime ? new Date(loggedInTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }) : ''}</em>
              </div>
              {/* <div className='admin_name'>
                <span> Duration :</span><em> {duration}</em>
              </div> */}
            </div>
          </div>
          <div className='sidebar '>
            {/* <div className='px-2 mt-3'>
                  <div className='employement my-3 mx-2 active ' onClick={GotoWorksheet}><i class="ri-user-add-line  employe_icon"></i> Worksheet</div>
                  <div className='employement my-3 mx-2 ' onClick={GotoAllWorksheet}><i class="ri-team-fill  employe_icon"></i> All Worksheet</div>
                  <div className='employement my-3 mx-2 ' onClick={GotoAdminfeedback}> <i class="ri-feedback-line employe_icon"></i> Admin Feedback</div>
                </div> */}
            <div className='mt-4'>
              <div className='mb-4'><NavLink to="/Traineeholiday" className=' my-3  employement' ><i class="ri-home-8-line employe_icon"></i> Home</NavLink> </div>
              <div className='mt-3'><NavLink to="/trainee" className=' my-3  employement' ><i class="ri-file-paper-line employe_icon"></i> Worksheet</NavLink> </div>
              <div className='mt-4'><NavLink to="/Allworksheet" className='employement my-3  ' ><i class="ri-file-paper-2-line employe_icon"></i> All Worksheet</NavLink></div>
              <div className='mt-4' ><NavLink to="/Adminfeedback" className='employement my-3  ' > <i class="ri-feedback-line employe_icon"></i> Admin Feedback</NavLink></div>
            </div>
          </div>
          <div className='button_item '>
            <button className='logout_button' onClick={Logout}> Logout <i className="fa-solid fa-arrow-right-from-bracket mx-2"></i></button>
          </div>

        </div>
      </div>
    </div>

  )
}

export default Adminsidebar

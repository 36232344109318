import React, { useState } from "react";
import Adminsidebar from "./Adminsidebar";
import apiList from "../lib/apiList";
import axios from "axios";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { Audio } from 'react-loader-spinner';

function AdminFeedback() {
  const [loginEmployee, setLoginEmployee] = useState([]);
  const [feedbackByHR, setFeedbackByHR] = useState([]);
  const [loading, setLoading] = useState(true);

  const goodImage = "./images/Good.png";
  const excellentImage = "./images/Excellent.png";
  const satisfy = "./images/feedback1.png";
  const average = "./images/feedback2.png";
  const nofeedBack = "./images/no_feedback.png"

  const fetchEmployee = async (req, res) => {
    try {
      const token = Cookies.get("token");

      const response = await axios.get(`${apiList.profile}`, {
        headers: {
          token: token,
        },
      });
      setLoginEmployee(response.data);
      setFeedbackByHR(response.data.weeklyFeedbackByHR);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-3 sidebar_bg">
          <Adminsidebar />
        </div>
        <div className="col-lg-9  d-lg-block d-xl-block d-none">
          <div className="" style={{ paddingTop: "25px" }}>
            <h3 className="admin_header1" style={{ color: "#6a2a69" }}>
              Hello <span>{loginEmployee.employeeName}</span>
            </h3>
            <h5 className="adimn_dashnbord1">
              Employee ID: <span>{loginEmployee.employeeId}</span>
            </h5>
            <h2 className="heads my-3 text-center">HR's Feedback</h2>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Audio
                type="Audio"
                color="#6a2a69"
                height={50}
                width={60}
              />
            </div>
          ) : (
            <>
              {feedbackByHR.length === 0 ? (
                <div className="text-center">
                  <img src={nofeedBack} alt="no  feedback" className="w-50"></img>
                </div>
              ) : (
                <table className="table table-bordered table-hover admin_table">
                  <thead className="table_head">
                    <tr>
                      <th>SNo</th>
                      <th>Week Range</th>
                      <th>Feedback</th>
                    </tr>
                  </thead>
                  <tbody className="table_body">
                    {feedbackByHR.map((employee, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {employee.startDate} to {employee.endDate}
                        </td>
                        <td>
                          <div className="d-flex justify-content-between">
                            <div className="">
                              <p className="m-0">{employee.feedback}</p>
                              <p className="suggestions_content m-0">
                                {employee.suggestions}
                              </p>
                            </div>
                            <div>
                              {employee.feedback === "Good" && (
                                <img
                                  src={goodImage}
                                  className="img-fluid good_img"
                                  alt="Good"
                                />
                              )}
                              {employee.feedback === "Excellent" && (
                                <img
                                  src={excellentImage}
                                  className="img-fluid good_img"
                                  alt="Excellent"
                                />
                              )}
                              {employee.feedback === "Average" && (
                                <img
                                  src={average}
                                  className="img-fluid good_img"
                                  alt="Average"
                                />
                              )}
                              {employee.feedback === "Satisfy" && (
                                <img
                                  src={satisfy}
                                  className="img-fluid good_img"
                                  alt="Satisfy"
                                />
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminFeedback;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './sidebar';
import axios from 'axios';
import apiList from '../lib/apiList';
import Cookies from 'js-cookie';
import { Audio } from 'react-loader-spinner';

function AllEmployee() {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [loading, setLoading] = useState(true);

  const [employees, setEmployees] = useState([]);

  function formatJoiningDate(joiningDate) {
    const date = new Date(joiningDate);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  const GotoPassword = (employeeId) => {
    navigate("/Forgotpassword", { state: { employeeId: employeeId } });
  };

  useEffect(() => {
    let token = Cookies.get('token')
    if (token === undefined) {
      navigate('/')
    }

    fetchApi();
  }, []);

  const filteredEmployees = employees.filter((employee) => {
    const employeeNameMatches = employee.employeeName.toLowerCase().includes(searchQuery.toLowerCase());
    const employeeIdMatches = employee.employeeId.toLowerCase().includes(searchQuery.toLowerCase());
    const categoryMatches = selectedCategory === 'All' || employee.employeeType === selectedCategory;
    return (employeeNameMatches || employeeIdMatches) && categoryMatches;
  });

  const employeeTypes = [...new Set(employees.map((employee) => employee.employeeType))];

  const token = Cookies.get('token');
  const fetchApi = async () => {
    try {
      const response = await axios.get(`${apiList.allEmployees}`, {
        headers: {
          "token": token,
        }
      });
      setEmployees(response.data);
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      setLoading(false); // Set loading to false on error
      console.error("Error fetching user data:", error);
    }
  };

  return (
    <div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-3  sidebar_bg' style={{ height: "100vh", overflow: "hidden" }}>
            <Sidebar />
          </div>
          <div className="col-lg-9 d-lg-block d-xl-block d-none table_item" style={{ height: "100vh", overflowY: "scroll" }}>
            <div className="admin_header ">Admin Dashboard</div>
            <div className="adimn_dashnbord">Welcome to the Perfex Admin Dashboard. Please Add your Employment Feedback.</div>
            <div className="search-bar row">
              <div className='col-md-8'>
                <input
                  type="text"
                  placeholder="Search by employee name or employee ID"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className='w-100 p-2 employee_filter_searchBar'
                />
              </div>
              <div className='col-md-4'>
                <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className=' w-100 employee_type_filter  employee_filter_select '
                >
                  <option value="All">Employee Type</option>
                  {employeeTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center mt-5">
                <Audio
                  type="Audio"
                  color="#6a2a69"
                  height={50}
                  width={60}
                />
              </div>
            ) : (
              <table className="table table-bordered text-center table-hover admin_table mt-3">
                <thead className="table_head">
                  <tr>
                    <th className='table_head1'>SNo</th>
                    <th className='table_head1'>Employee Name</th>
                    <th className='table_head1'>Email</th>
                    <th className='table_head1'>Employee ID</th>
                    <th className='table_head1'>Employee Type</th>
                    <th className='table_head1'>Mobile Number</th>
                    <th className='table_head1'>Joining Date</th>
                    <th className='table_head1'>Password</th>
                  </tr>
                </thead>
                <tbody className="table_body">
                  {searchQuery === '' ? (
                    // Render all employees when there's no search query
                    filteredEmployees.map((employee, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{employee.employeeName}</td>
                        <td>{employee.email}</td>
                        <td>{employee.employeeId}</td>
                        <td>{employee.employeeType}</td>
                        <td>{employee.phoneNumber}</td>
                        <td className="tdFont">{formatJoiningDate(employee.joiningDate)}</td>
                        <td><a className="" onClick={() => GotoPassword(employee.employeeId)}> Change</a></td>
                      </tr>
                    ))
                  ) : (
                    // Render filtered employees when there's a search query
                    filteredEmployees.map((employee, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{employee.employeeName}</td>
                        <td>{employee.email}</td>
                        <td>{employee.employeeId}</td>
                        <td>{employee.employeeType}</td>
                        <td>{employee.phoneNumber}</td>
                        <td className="tdFont">{formatJoiningDate(employee.joiningDate)}</td>
                        <td><a className="" onClick={() => GotoPassword(employee.employeeId)}> Change</a></td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllEmployee;

import React from "react";
import { useNavigate } from "react-router-dom";
import Adminsidebar from "./Adminsidebar";
import { useState, useEffect } from "react";
import apiList from "../lib/apiList";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Cookies from "js-cookie";

const currentDate = new Date();
const days = [];
// Find the start of the week (Monday)
currentDate.setDate(currentDate.getDate() - ((currentDate.getDay() + 6) % 7));
// Collect dates from Monday to Friday
for (let i = 0; i < 6; i++) {
  days.push(new Date(currentDate));
  currentDate.setDate(currentDate.getDate() + 1);
}
// Format the dates as needed
const formattedDay = days.map((date) => date.toLocaleDateString().split("/"));
var formattedDates = formattedDay.map(
  (v) => v[1].padStart(2, "0") + "-" + v[0].padStart(2, "0") + "-" + v[2]
);
// setWeekDays(formattedDates);
// console.log(formattedDates);
const date = new Date();
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const selectedMonth = monthNames[date.getMonth()];

// console.log(currentDate);

function Trainee() {
  let navigate = useNavigate();
  const [disableTextarea, setDisableTextarea] = useState(false);
  const [weekStart, setWeekStart] = useState("");
  const [traineeSheet, setTraineeSheet] = useState([]);
  const [selectTrainer, setTrainer] = useState("");
  const [feedback, setFeedback] = useState("");
  const [weekDays, setWeekDays] = useState(formattedDates);
  const [monthName, setMonthName] = useState(selectedMonth);
  const [employeeId, setEmployeeId] = useState([]);
  const [textAreaDay, settextAreaDay] = useState({});
  const [leaveCheckbox, setleaveCheckbox] = useState({});
  const [employeeWorksheet, setEmployeeWorksheet] = useState([]);
  const [weekworksheets, setWeekWorksheet] = useState({});
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  const objectsarray = Object.keys(weekworksheets);
  const objectsarraydates = objectsarray?.length || {};
  // console.log(objectsarraydates);

  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const currentDayOfWeek = new Date().getDay();
  const isFriday = currentDayOfWeek === 6; // 5 represents Friday

  const handleFeedback = (e) => {
    setFeedback(e.target.value);
  };
  useEffect(() => {
    let token = Cookies.get("token");
    if (token === null) {
      navigate("/");
    }
  }, []);
  const handleTrainerSelection = (event) => {
    setTrainer(
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-value"
      )
    );
  };
  // console.log(selectTrainer);
  // console.log(feedback);

  const fromDate = weekDays[0];
  // const fromDate =new Date(formattedDay[0]).getTime();
  const toDate = weekDays[weekDays.length - 1];
  // const toDate=new Date(formattedDay[formattedDay.length-1]).getTime();
  // console.log(fromDate, toDate);
  const onFeedbackFormSubmit = (e) => {
    e.preventDefault();
    const token = Cookies.get("token");
    if (selectTrainer && feedback) {
      const api = `${apiList.worksheetByTrainee}/${employeeId}`;
      try {
        const response = axios.post(
          api,
          {
            weekperiod: [
              {
                startDate: fromDate,
                endDate: toDate,
                trainerName: selectTrainer,
                feedbackByEmployeeforTrainer: feedback,
              },
            ],
          },
          {
            headers: {
              token: token,
            },
          }
        );
        console.log("Feedback updated successfully:", response.data);
        toast("Feedback updated successfully", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          className: "custom-toast-custom",
        });
      } catch (error) {
        console.error("Error updating feedback:", error);
      }
    }
    // toast.warning("Pending some fields Please check")
  };
  const [isOnLeave, setIsOnLeave] = useState(false);

  const saveData = async (savingDate, savingTextarea) => {
    const token = Cookies.get("token");
    console.log(savingTextarea);
    const api = `${apiList.worksheetByTrainee}/${employeeId}`;
    console.log(api);
    const isHolidayDate = holidayDates.includes(savingDate);
    const words = savingTextarea.split(/\s+/).filter((word) => word !== "");
    if (leaveCheckbox === false && !isHolidayDate && words.length < 200) {
      toast.error("Text must contain at least 200 words.");
      return;
    }

    // Save data with the word count requirement
    try {
      const response = await axios.post(
        api,
        {
          savingDate: savingDate,
          savingTextarea: savingTextarea,
          weekperiod: [
            {
              startDate: fromDate,
              endDate: toDate,
              trainerName: selectTrainer,
              feedbackByEmployeeforTrainer: feedback,
              worksheets: {
                [savingDate]: savingTextarea,
              },
            },
          ],
        },
        {
          headers: {
            token: token,
          },
        }
      );

      console.log("Feedback updated successfully:", response.data);
      toast("Feedback updated successfully", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: "custom-toast-custom",
      });

      // Update the local state with the saved data
      setWeekWorksheet((prevWeekworksheets) => ({
        ...prevWeekworksheets,
        [savingDate]: savingTextarea,
      }));
    } catch (error) {
      console.error("Error updating feedback:", error);
    }
  };

  const disableTextareaForFutureDays = (date) => {
    const currentDate = new Date();
    return date > currentDate;
  };

  useEffect(() => {
    // Check if the initial data is loaded before disabling the textarea
    if (initialDataLoaded) {
      setDisableTextarea(disableTextareaForFutureDays(new Date()));
    }
  }, [initialDataLoaded]);

  const fetchEmployee = async (req, res) => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(`${apiList.worksheetProfile}`, {
        headers: {
          token: token,
        },
      });
      setEmployeeId(response.data.employeeId);
      // console.log(response.data.employeeId);
      setTraineeSheet(response.data.weekperiod);
      fetchReview(response.data.employeeId);
    } catch (error) {}
  };
  console.log(traineeSheet);
  const fetchReview = async (empId) => {
    try {
      // console.log("COME");
      const token = Cookies.get("token");
      const response = await axios.get(
        `${apiList.indEmployeWorkSheet}/${empId}`,
        {
          headers: {
            token: token,
          },
        }
      );
      setEmployeeWorksheet(response.data);
      // console.log("Data", response.data);
      const datesMatch = response.data.weekperiod.find((week) => {
        return week.startDate === fromDate && week.endDate === toDate;
      });
      const datadatesMatch = datesMatch?.worksheets || {};

      settextAreaDay(datadatesMatch);
      // console.log(datadatesMatch);
      setWeekWorksheet(datadatesMatch);
    } catch (error) {}
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  const [punchInTime, setPunchInTime] = useState(null);
  const [running, setRunning] = useState(null);
  const [duration, setDuration] = useState(null);
  const [time, setTime] = useState(null);
  const [totalDuration, setTotalDuration] = useState(null);
  const [previousDuration, setPreviousDuration] = useState([]);
  const [leavesDataArray, setLeavesArraay] = useState([]);

  const handlePunchIn = () => {
    const punchInTimestamp = new Date();
    setTime(new Date());
    const expirationTime = new Date(
      punchInTimestamp.getTime() + 10 * 60 * 60 * 1000
    ); // Add 2 minutes to the current time
    setPunchInTime(punchInTimestamp);
    Cookies.set("punchInTime", punchInTimestamp, { expires: expirationTime });

    if (disableTextareaForFutureDays(new Date())) {
      setDisableTextarea(true);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRunning(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const punchInTime = Cookies.get("punchInTime");
    if (punchInTime) {
      setPunchInTime(punchInTime);
    }
  }, []);

  useEffect(() => {
    if (punchInTime) {
      const timeDifference = running - new Date(Cookies.get("punchInTime"));

      const hours = String(Math.floor(timeDifference / 3600000)).padStart(
        2,
        "0"
      );
      const minutes = String(
        Math.floor((timeDifference % 3600000) / 60000)
      ).padStart(2, "0");
      const seconds = String(
        Math.floor((timeDifference % 60000) / 1000)
      ).padStart(2, "0");

      const formattedDuration = `${hours}:${minutes}:${seconds}`;

      setDuration(formattedDuration);
    }
  }, [running, punchInTime]);

  const fetchEmployeeDuration = async (req, res) => {
    try {
      const token = Cookies.get("token");

      const response = await axios.get(`${apiList.worksheetProfile}`, {
        headers: {
          token: token,
        },
      });
      setPreviousDuration(response.data.workingHours);
      setLeavesArraay(response.data.leavesData);
    } catch (error) {}
  };

  useEffect(() => {
    fetchEmployeeDuration();
  }, [traineeSheet, previousDuration, leavesDataArray]);

  const [employees, setEmployees] = useState([]);

  const fetchApi = async () => {
    let token = Cookies.get("token");
    try {
      const response = await axios.get(`${apiList.getTrainersList}`, {
        headers: {
          token: token,
        },
      });
      setEmployees(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchApi();
  }, []);

  useEffect(() => {
    // Get the current day of the week (0 for Sunday, 6 for Saturday)
    const currentDayOfWeek = new Date().getDay();

    // Enable or disable the save button based on the day of the week
    const isFriday = currentDayOfWeek === 6; // 5 is Friday
    if (isFriday) {
      // It's Friday, so enable the save button for the last textarea
      setDisableSaveButton(false);
    } else {
      // It's not Friday, so disable the save button
      setDisableSaveButton(true);
    }

    // Enable or disable the submit button based on the day of the week
    if (isFriday && objectsarraydates >= 6) {
      // It's Friday and there are at least 4 objects, so enable the submit button
      setDisableSubmitButton(false);
    } else {
      // It's not Friday or there are less than 4 objects, so disable the submit button
      setDisableSubmitButton(true);
    }
  }, [objectsarraydates]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is 0-indexed, so add 1
    const year = date.getFullYear();

    // Use template literals to format the date in "DD-MM-YYYY" format
    return `${day < 10 ? "0" : ""}${day}-${
      month < 10 ? "0" : ""
    }${month}-${year}`;
  }

  const [holidays, setHolidays] = useState([]);
  const [showAllHolidays, setShowAllHolidays] = useState(false);

  const [currentDateHolidayWish, setCurrentDateHolidayWish] = useState("");

  useEffect(() => {
    // Load holidays from the backend when the component mounts.
    loadHolidays();
  }, []);

  const loadHolidays = async () => {
    try {
      const response = await axios.get(`${apiList.postHoliday}`);
      // console.log("Holidays from server:", response.data);
      const sortedHolidays = response.data.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });
      setHolidays(sortedHolidays);
    } catch (error) {
      console.error("Error loading holidays:", error);
    }
  };

  const isHoliday = (date) => {
    return holidays.some((holiday) => {
      const formattedHolidayDate = formatDate(holiday.date);
      return formattedHolidayDate === date;
    });
  };

  const today = new Date();
  const formattedToday = formatDate(today);
  const isTodayHoliday = isHoliday(formattedToday);

  const todayHoliday = holidays.find(
    (holiday) => formatDate(holiday.date) === formattedToday
  );

  useEffect(() => {
    // Check if the current date is a holiday and has a holiday wish
    if (isTodayHoliday && todayHoliday) {
      setCurrentDateHolidayWish(todayHoliday.holidayWish);
    } else {
      // Reset the holiday wish if it's not a holiday
      setCurrentDateHolidayWish("");
    }
  }, [isTodayHoliday, todayHoliday]);

  const holidayDates = holidays.map((holiday) => {
    const parts = holiday.date.split("-");
    if (parts.length === 3) {
      const [year, month, day] = parts;
      return `${day}-${month}-${year}`;
    }
    return holiday.date;
  });

  // console.log(holidayDates);
  // console.log(weekDays);

  function getWordCount(text) {
    const words = text.split(/\s+/).filter((word) => word !== "");
    return words.length;
  }

  const [isCheckboxChecked, setIsCheckboxChecked] = useState({});
  const handleCheckBox = async (savingDate, isChecked) => {
    setIsCheckboxChecked((prev) => ({
      ...prev,
      [savingDate]: isChecked,
    }));
    settextAreaDay((prev) => ({
      ...prev,
      [savingDate]: isChecked ? "I am on leave" : "",
    }));
    const token = Cookies.get("token");
    const api = `${apiList.worksheetByTrainee}/${employeeId}`;
    const leaveApi = `${apiList.leaveUpdate}/${employeeId}`;
    console.log(leaveApi);
    try {
      const response = await axios.post(
        api,
        {
          savingDate: savingDate,
          savingTextarea: "I'm on leave",
          weekperiod: [
            {
              startDate: fromDate,
              endDate: toDate,
              trainerName: selectTrainer,
              feedbackByEmployeeforTrainer: feedback,
              worksheets: {
                [savingDate]: "I'm on leave",
              },
            },
          ],
        },
        {
          headers: {
            token: token,
          },
        }
      );

      console.log("Feedback updated successfully:", response.data);
      // toast("Leave Updated Successfully", {
      // 	position: "top-right",
      // 	autoClose: 1000,
      // 	hideProgressBar: false,
      // 	closeOnClick: true,
      // 	pauseOnHover: true,
      // 	draggable: true,
      // 	progress: undefined,
      // 	theme: "colored",
      // 	className: "custom-toast-custom",
      // });

      // Update the local state with the saved data
      setWeekWorksheet((prevWeekworksheets) => ({
        ...prevWeekworksheets,
        [savingDate]: "I'm on leave",
      }));
    } catch (error) {
      console.error("Error updating feedback:", error);
    }

    // axios
    // .post(leaveApi, {
    // 	leavesData :  [
    // 		...leavesDataArray,
    // 		{
    // 			date : savingDate,
    // 			leaveStatus : "I'm on leave"
    // 		}
    // 	]

    // },  {
    //   headers: {
    //     token: token,
    //   },
    // })
    // .then((response) => {
    //   // setData(response.data)
    //   if (response.status === 200) {
    //     console.log("leave updated");

    //   }
    // })
    // .catch((error) => {
    //   console.log(error.response.data);
    //   toast.error("employee already added");
    // });
    // Check if savingDate exists in the leavesDataArray
    const dateExists = leavesDataArray.some((item) => item.date === savingDate);

    if (dateExists) {
      // Date already exists, show an alert or handle it as needed
      toast.error("Leave for this date already exists.");
    } else {
      // Date does not exist, proceed with the POST request

      const response1 = axios
        .post(
          leaveApi,
          {
            leavesData: [
              ...leavesDataArray,
              {
                date: savingDate,
                leaveStatus: "I'm on leave",
              },
            ],
          },
          {
            headers: {
              token: token,
            },
          }
        )
        // .post(
        // 	leaveApi,
        // 	{
        // 		leavesData: [
        // 			...leavesDataArray,
        // 			{
        // 				date: savingDate,
        // 				leaveStatus: "I'm on leave",
        // 			},
        // 		],
        // 	},
        // 	{
        // 		headers: {
        // 			token: token,
        // 		},
        // 	}

        // )
        .then((response1) => {
          if (response1.status === 200) {
            console.log("Leave updated");
            toast("Leave Updated Successfully", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              className: "custom-toast-custom",
            });
          }
        })
        .catch((error) => {
          console.log(error.response1.data);
        });
    }
  };

  const leaveDates = leavesDataArray.map((item) => item.date);

  const isSaveButtonDisabled = weekDays.map((date) => {
    const isOnLeave = leaveDates.includes(date); // Check if the date is in leavesDataArray
    // Check if the date is not a holiday

    // Set the flag to disable the save button if it's on leave or not a weekday
    return isOnLeave;
  });

  // console.log(textAreaDay);
  return (
    <div className="container-fluid ">
      <div className="row">
        <div
          className="col-lg-3 sidebar_bg sticky-top "
          style={{ height: "100vh" }}
        >
          <ToastContainer />
          <Adminsidebar />
        </div>
        <div className="col-lg-9">
          {/* <div className=" d-flex justify-content-between ">
						<button
							className=" submit-button1"
							onClick={handlePunchIn}
							disabled={punchInTime !== null}
						>
							{" "}
							Punch In{" "}
						</button>
						<button className=" submit-button1" onClick={handlePunchOut}>
							Punch Out{" "}
						</button>
					</div>
					<div className="d-flex justify-content-end">
						<p className="mt-2 note_para">
							Note :Punch out is mandatory at the end of day and once punched
							out your working duration for the day will be closed
						</p>
					</div>
					<div className="d-flex justify-content-end">
						{punchInTime === null ? (
							<h6></h6>
						) : (
							<h5>
								Punched In time :{" "}
								<span>
									{punchInTime
										? new Date(punchInTime).toLocaleTimeString([], {
												hour: "2-digit",
												minute: "2-digit",
												second: "2-digit",
												hour12: true,
										  })
										: ""}
								</span>{" "}
							</h5>
						)}
					</div>
					<div className="d-flex justify-content-end">
						{punchInTime === null ? (
							<h6></h6>
						) : (
							<p className="m-0">{duration}</p>
						)}
					</div> */}

          <div className="text-center">
            <div
              className="october"
              style={{ color: "#6a2a69", paddingTop: "25px" }}
            >
              {monthName}
            </div>
            <div className="current">Give Feedback for the current week</div>
          </div>
          <div
            className="week-calendar"
            style={{
              height: "100vh",
              overflowY: "scroll",
              paddingRight: "10px",
            }}
          >
            {weekDays.map((v, index) => (
              <div className="form-group purple-border" key={v}>
                <label htmlFor="" style={{ float: "left", fontSize: "18px" }}>
                  Report for {v}
                </label>
                <textarea
                  className="form-control pl-3 textAreaFields"
                  placeholder="Please share..."
                  onChange={(e) =>
                    settextAreaDay((prev) => ({ ...prev, [v]: e.target.value }))
                  }
                  type="text"
                  rows="6"
                  value={textAreaDay[v] || ""}
                  disabled={isCheckboxChecked[v] || isSaveButtonDisabled[index]}
                />
                <label>
                  {/* <input
										type="checkbox"
										checked={isOnLeave[v]}
										onChange={(e) => {
											handleCheckBox(e,v)
											// settextAreaDay((prev) => ({ ...prev, [v]: e.target.checked ? "I am on leave" : "" }));
											// setleaveCheckbox((prev) => ({ ...prev, [v]: e.target.checked }));
											
										}}
									/> */}
                  <input
                    type="checkbox"
                    checked={isOnLeave[v]}
                    onChange={(e) => handleCheckBox(v, e.target.checked)}
                  />
                  &nbsp;I am on leave
                </label>
                {holidayDates.includes(v) ? (
                  <button
                    className="employee_filter_select p-2 float-right my-2 px-5 "
                    style={{ fontSize: "16px" }}
                    onClick={(e) => saveData(v, textAreaDay[v])}
                    disabled={isCheckboxChecked[v]}
                  >
                    Holiday
                  </button>
                ) : (
                  <button
                    className="employee_filter_select p-2 float-right my-2 px-5"
                    style={{ fontSize: "16px" }}
                    onClick={(e) => {
                      if (getWordCount(textAreaDay[v]) >= 200) {
                        saveData(v, textAreaDay[v]);
                      } else {
                        toast.error("Text must contain at least 200 words.");
                      }
                    }}
                    disabled={isCheckboxChecked[v]}
                  >
                    Save
                  </button>
                )}
              </div>
            ))}
            <form>
              <div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 p-0">
                    <div className="">
                      <label>Current Trainer</label>
                      <div>
                        <select
                          className="w-100 select_item"
                          onChange={handleTrainerSelection}
                        >
                          <option className="hidden" value="">
                            Select Trainer
                          </option>
                          {employees.map((employee) => (
                            <option
                              className="name_item"
                              key={employee._id} // Use a unique key for each option
                              data-value={employee.employeeName}
                              value={selectTrainer || ""}
                            >
                              {employee.employeeName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <label className="my-2 week_item">
                  Feedback for Trainer :{" "}
                </label>
                <textarea
                  className="w-100 pl-3 pt-2"
                  rows={4}
                  onChange={handleFeedback}
                  // value={traineeSheet.feedbackByEmployeeforTrainer || ""}
                ></textarea>
                <button
                  className="employee_filter_select p-2 px-5 mt-2"
                  style={{
                    fontSize: "17px",
                    backgroundColor: "#6a2a69",
                    color: "#ffffff",
                  }}
                  onClick={onFeedbackFormSubmit}
                >
                  submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trainee;

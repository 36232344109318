import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import Sidebar from './sidebar';
import apiList from '../lib/apiList';
import Cookies from 'js-cookie';

function UpdateFeedback() {
  const navigate = useNavigate();

  const GotoFeedback = () => {
    navigate("/hrviewingtrainerfeedback", { state: { employeeId } });
  };

  const { state } = useLocation();
  const { employeeId } = state;
  const [employeesData, setEmployeesData] = useState([]);
  const [weekStartDate, setWeekStartDate] = useState("");
  const [weekEndDate, setWeekEndDate] = useState("");

  const [feedbackArray, setFeedBackArray] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [suggestions, setSuggestions] = useState("");
  const [finalEndDate, setFinalEndDate] = useState("");
  const [finalStartDate, setFinalStartDate] = useState("");

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const selectStartDate = (e) => {
    const selectedDate = new Date(e.target.value);
    const dayOfWeek = selectedDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

    if (dayOfWeek === 1) {
      // Valid start date (Monday)
      const formattedDate = `${selectedDate.getDate().toString().padStart(2, '0')}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getFullYear()}`;

      setWeekStartDate(e.target.value);
      setFinalStartDate(formattedDate)

      // Check if weekEndDate is also selected and validate the range
      if (weekEndDate) {
        const endDate = new Date(weekEndDate);
        const dayDifference = (endDate - selectedDate) / (1000 * 60 * 60 * 24) + 1;
        if (dayDifference !== 6) {
          // Invalid date range, show a toast
          toast.error("Week days range must be 6 days.");
          setWeekEndDate("");
        }
      }
    } else {
      // Invalid start date, show a toast
      toast.error("Please start from Monday.");
    }
  };

  const selectEndDate = (e) => {
    const selectedDate = new Date(e.target.value);
    const dayOfWeek = selectedDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

    if (dayOfWeek === 6) {
      // Valid end date (Friday)
      const formattedDate = `${selectedDate.getDate().toString().padStart(2, '0')}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getFullYear()}`;
      setWeekEndDate(e.target.value);
      setFinalEndDate(formattedDate)

      // Check if weekStartDate is also selected and validate the range
      if (weekStartDate) {
        const startDate = new Date(weekStartDate);
        const dayDifference = (selectedDate - startDate) / (1000 * 60 * 60 * 24) + 1;
        if (dayDifference !== 6) {
          // Invalid date range, show a toast
          toast.error("Week days range must be 6 days.");
          setWeekStartDate("");
        }
      }
    } else {
      // Invalid end date, show a toast
      toast.error("Please end on Saturday.");
    }
  };

  const review = async () => {
    const token = Cookies.get("token");
    try {
      const api = `${apiList.indEmployeeParams}/${employeeId}`;
      const response = await axios.get(api, {
        headers: {
          token: token,
        },
      });
      setEmployeesData(response.data);
      setFeedBackArray(response.data.weeklyFeedbackByHR);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    let token = Cookies.get('token');
    if (token === undefined) {
      navigate('/');
    }
    review();
  }, []);


  const updateEmployeeFeedback = async () => {
    if (!weekStartDate || !weekEndDate || !selectedOption) {
      toast.error("Please fill in all the details");
    } else {
      const startDate = new Date(weekStartDate);
      const endDate = new Date(weekEndDate);
      const dayDifference = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;

      if (dayDifference === 6) {
        // Valid date range
        const token = Cookies.get("token");
        const employeeId = state.employeeId;

        const api = `${apiList.feedBackByHR}/${employeeId}`;

        try {
          const response = await axios.put(
            api,
            {
              weeklyFeedbackByHR: [
                {
                  startDate: finalStartDate,
                  endDate: finalEndDate,
                  feedback: selectedOption,
                  suggestions: suggestions,
                },
              ],
            },
            {
              headers: {
                token: token,
              },
            }
          );

          if (response.status === 200) {
            // Handle a successful response
            console.log("Feedback updated successfully:", response.data);

            setWeekStartDate("");
            setWeekEndDate("");
            setSelectedOption("");
            setSuggestions("");
            toast.success('Feedback updated successfully', {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              className: 'custom-toast-custom',
            });
            review();
          }
          if (response.status === 400) {
            // Handle a 400 Bad Request response
            alert("Feedback for the week selected has already been updated");
          }
        }

        catch (error) {
          toast.error("Feedback for the week selected has already been updated", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: 'custom-toast-error-custom',
          });
          console.error("Error updating feedback:", error);
        }
      } else {
        // Invalid date range, show a toast
        toast.error("Week days range must be 5 days.");
      }
    }
  };


  return (
    <div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-3  sidebar_bg'>
            <ToastContainer />
            <Sidebar />
          </div>
          <div className='col-lg-9 d-lg-block d-xl-block d-none feddback_item ' style={{ height: "100vh", overflowY: "scroll" }}>
            <div className="" style={{ paddingTop: "25px" }}>
              <h3 className="admin_header1" style={{ color: "#6a2a69" }}>
                Employee Name: <span>{employeesData.employeeName}</span>
              </h3>
              <h5 className="adimn_dashnbord1">
                Employee ID: <span>{employeesData.employeeId}</span>
              </h5>
            </div>
            <div>
              <div className='card my-3'>
                <div className='week_item my-2'>Give Feedback for the week Monday to Saturday </div>
                <div><b>Select start date : </b><input type='date' className='p-1 date_item w-25 mr-5' onChange={selectStartDate}></input> <b>select week end date : </b>  <input type='date' className='p-1 date_item w-25' onChange={selectEndDate}></input></div>
                {weekStartDate && weekEndDate && <div className='week_item  mt-3'> <span className='date_item p-2 '> You are giving feedback for the week {finalStartDate} to {finalEndDate}</span> </div>}
                <div className='mt-3'>
                  <input type='radio' className='mx-2' value='Excellent' checked={selectedOption === 'Excellent'} onChange={handleRadioChange} /> <label>Excellent</label>
                  <input type='radio' className='mx-2' value='Good' checked={selectedOption === 'Good'} onChange={handleRadioChange} /> <label>Good</label>
                  <input type='radio' className='mx-2' value='Average' checked={selectedOption === 'Average'} onChange={handleRadioChange} /> <label>Average</label>
                  <input type='radio' className='mx-2' value='Satisfy' checked={selectedOption === 'Satisfy'} onChange={handleRadioChange} /> <label>Satisfy</label>
                </div>
                <textarea className='m-2 w-50 p-2' rows={5} placeholder='Any suggestions (optional)' value={suggestions} onChange={((e) => setSuggestions(e.target.value))} ></textarea>
                <div className='view_button mt-2'>
                  <button className='give_button' onClick={updateEmployeeFeedback}>Send Feedback</button>
                </div>
              </div>
            </div>
            <div className='my-4'>
              <button className='back_button' onClick={GotoFeedback}>Trainer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateFeedback;

import React, { useEffect, useState } from "react";
import TrainerSidebar from "./TrainerSidebar";
import { useLocation } from "react-router-dom";
import {IoIosArrowBack} from 'react-icons/io';
import apiList from "../lib/apiList";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from "js-cookie";

const TrainerReview = () => {
  const { state } = useLocation();
  const employeeId = state.employeeId;
  const [traineeDetails, setTraineeDetails] = useState("");
  const [traineeWorksheet, setTraineeWorksheet] = useState([]);
  const [weekStartDate, setWeekStartDate] = useState("");
  const [weekEndDate, setWeekEndDate] = useState("");
  const [courseName, setCourseName] = useState("");
  const [taskStatus, setTaskStatus] = useState("");
  const [weekPerformance, setWeekPerformance] = useState("");
  const [selectedWeeks, setSelectedWeeks] = useState([]); // To keep track of selected weeks

  // Validation function to check if all fields are filled
  const validateFields = () => {
    if (
      weekStartDate &&
      weekEndDate &&
      courseName &&
      taskStatus &&
      weekPerformance
    ) {
      return true;
    }
    return false;
  };

  const onBackToTrainer=()=>{
    window.location.href="/trainer"
  }

  const review = async () => {
    const token = Cookies.get("token");
    try {
      const api = `${apiList.indEmployeWorkSheet}/${employeeId}`;
      const response = await axios.get(api, {
        headers: {
          token: token,
        },
      });
      setTraineeDetails(response.data);
      setTraineeWorksheet(response.data.weeklyFeedBackByTrainer);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (employeeId) {
      review();
    }
  }, [traineeWorksheet, traineeDetails]);

  // Validation function to check if the week has already been selected
  const isWeekSelected = (startDate) => {
    return selectedWeeks.includes(startDate);
  };

  // Update the selected weeks state when a week is selected
  const updateSelectedWeeks = (startDate) => {
    setSelectedWeeks((prevSelectedWeeks) => [...prevSelectedWeeks, startDate]);
  };

//   const selectStartDate = (e) => {
//     const selectedDate = new Date(e.target.value);
//     const selectedDay = selectedDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
//     const day = String(selectedDate.getDate()).padStart(2, '0');
// const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
// const year = selectedDate.getFullYear();
// const formattedDate = `${day}-${month}-${year}`;

//     if (selectedDay !== 1) {
//       toast.error('Please select a start date that is a Monday.', {
//         position: "top-right",
//         autoClose: 1000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//       });
//     } else {
//       const startDate = selectedDate.toISOString().split('T')[0];
//       if (isWeekSelected(startDate)) {
//         toast.error('This week is already selected.', {
//           position: "top-right",
//           autoClose: 1000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//         });
//       } else {
//         updateSelectedWeeks(startDate);

//         const endDate = new Date(selectedDate);
//         endDate.setDate(selectedDate.getDate() + 5);

//         setWeekStartDate(formattedDate);
//         setWeekEndDate(endDate.toISOString().split('T')[0]);
//       }
//     }
//   };

const selectStartDate = (e) => {
  const selectedDate = new Date(e.target.value);
  const selectedDay = selectedDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
  const day = String(selectedDate.getDate()).padStart(2, '0');
  const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
  const year = selectedDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  if (selectedDay !== 1) {
    toast.error('Please select a start date that is a Monday.', {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  } else {
    const startDate = selectedDate.toISOString().split('T')[0];
    if (isWeekSelected(startDate)) {
      toast.error('This week is already selected.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } else {
      updateSelectedWeeks(startDate);

      const endDate = new Date(selectedDate);
      endDate.setDate(selectedDate.getDate() + 5);

      const endDay = String(endDate.getDate()).padStart(2, '0');
      const endMonth = String(endDate.getMonth() + 1).padStart(2, '0');
      const endYear = endDate.getFullYear();
      const formattedEndDate = `${endDay}-${endMonth}-${endYear}`;

      setWeekStartDate(formattedDate);
      setWeekEndDate(formattedEndDate);
    }
  }
};


  // const selectEndDate = (e) => {
  //   const selectedDate = new Date(e.target.value);
  //   const selectedDay = selectedDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

  //   if (selectedDay !== 6) {
  //     toast.error('Please select an end date that is a Friday.', {
  //       position: "top-right",
  //       autoClose: 1000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //     });
  //   } else {
  //     const endDate = new Date(selectedDate);
  //     endDate.setDate(selectedDate.getDate() + 4);

  //     setWeekStartDate(weekStartDate);
  //     setWeekEndDate(endDate.toISOString().split('T')[0]);
  //   }
  // };

  const handleCourse = (e) => {
    setCourseName(e.target.value);
  };

  const handleTaskName = (e) => {
    setTaskStatus(e.target.value);
  };

  const handleWeekPerformance = (e) => {
    setWeekPerformance(e.target.value);
  };

  const updateEmployeeFeedback = async () => {
    const token = Cookies.get("token");
    const employeeId = state.employeeId;
    if (employeeId) {
      if (validateFields()) {
        const api = `${apiList.feedbackByTrainer}/${employeeId}`;
        const newFeedback = {
          startDate: weekStartDate,
          endDate: weekEndDate,
          courseName: courseName,
          feedback: weekPerformance,
          taskStatus: taskStatus,
        };

        // Check if the selected week range already exists
        if (traineeWorksheet.some((week) =>
          week.startDate === newFeedback.startDate &&
          week.endDate === newFeedback.endDate
        )) {
          toast.error('This week is already selected.', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          try {
            const response = await axios.put(
              api,
              {
                weeklyFeedBackByTrainer: [...traineeWorksheet, newFeedback],
              },
              {
                headers: {
                  token: token,
                },
              }
            );
            toast.success('Feedback updated successfully', {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              // theme: "colored",
            });
            setWeekStartDate("");
            // setWeekEndDate("");
            setTaskStatus("");
            setCourseName("");
            setWeekPerformance("");
          } catch (error) {
            console.error("Error updating feedback:", error);
          }
        }
      } else {
        toast.error('Please fill in all the details', {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          // theme: "colored",
        });
      }
    }
  };


  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3" style={{ backgroundColor: "#733272" }}>
            <TrainerSidebar />
            <ToastContainer />
          </div>
          <div
            className="col-md-9 trainer_dashboard"
            style={{ backgroundColor: "#ffffff" }}
          >
            <button className="back-arrow" onClick={onBackToTrainer}>
              <IoIosArrowBack />
            </button>

            <h1 className="heads" style={{ color: "#6a2a69" }}>Submit Feedback Section</h1>
            <div className="">
              <div className="admin_header1">
                Employee Name: <span>{traineeDetails.employeeName}</span>
              </div>
              <div className="adimn_dashnbord1">
                Employee ID: <span>{traineeDetails.employeeId}</span>
              </div>
            </div>
            <div>
              <div className="week_item my-3">
                Give Feedback for the week Monday to Saturday
              </div>
              <div>
                <b>Select week Range : </b>{" "}
                <input
                  type="date"
                  className="p-1 date_item w-25 mr-5"
                  onChange={selectStartDate}
                ></input>
                {/* Select end date{" "}
                <input
                  type="date"
                  className="m-2"
                  onChange={selectEndDate}
                ></input> */}
              </div>
            </div>
            {weekStartDate && (
              <div className='week_item mt-3'> <span className='date_item1'> {weekStartDate} to {weekEndDate}</span> </div>
            )}


            <input
              type="text"
              className="form-control mt-4 p-4"
              placeholder="Enter Course / Task Name"
              onChange={handleCourse}
              value={courseName}
            />

            <input
              type="text"
              className="form-control mt-3 p-4"
              placeholder="Task Status"
              onChange={handleTaskName}
              value={taskStatus}
            />

            <textarea
              rows={5}
              className="form-control mt-3"
              placeholder="Write week performance"
              onChange={handleWeekPerformance}
              value={weekPerformance}
            />

            <div className="text-right">
              <button
                className="mt-3"
                style={{
                  backgroundColor: "#6a2a69",
                  color: "#ffffff",
                  border: "1px solid #6a2a69",
                  padding: "7px 15px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                }}
                onClick={updateEmployeeFeedback}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    </div>
  );
};

export default TrainerReview;

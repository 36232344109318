// export const server = "http://localhost:4444";
export const server = "https://pfx-server-dashboard.onrender.com"
// api list 

const apiList = {
  login: `${server}/login`,
  profile: `${server}/individualEmployee`,
  addEmployee: `${server}/addEmployee`,
  addEmployeWorkSheet: `${server}/addEmployeeWorkSheet`,
  allEmployees: `${server}/allemployees`,
  indEmployeWorkSheet: `${server}/individualEmployeeTimesheet`,
  feedBackByHR: `${server}/feebackforemployee`,
  indEmployeeParams: `${server}/individualEmployee`,
  traineesListbyTrainerName: `${server}/traineesList`,
  feedbackByTrainer: `${server}/feebackforemployeeByTrainer`,
  worksheetByTrainee: `${server}/employeefeedback`,
  worksheetProfile: `${server}/individualEmployeeWorksheet`,
  workingDuration: `${server}/duration`,
  employeefeedbackrange: `${server}/employeefeedbackrange`,
  getTrainersList: `${server}/getTrainersList`,
  postHoliday: `${server}/holidays`,
  leaveUpdate :  `${server}/leaveupdate`,
  traineesList : `${server}/getTraineesList`,
  changepassword : `${server}/changepassword`

};

export default apiList;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import React from "react";
import TrainerSidebar from '../TrainerSidebar';
import { Link } from "react-router-dom";
import axios from 'axios';
import apiList from '../../lib/apiList';
import Cookies from 'js-cookie';
import { Audio } from 'react-loader-spinner';

const TraineesList = () => {
  let navigate = useNavigate();
  const [loginEmployee, setLoginEmployee] = useState("")
  const [traineesArray, setTraineesList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let token = Cookies.get("token");
    if (token === undefined) {
      navigate("/");
    }
  }, []);

  const GotoFeedback = (employeeId) => {
    navigate("/traineeStatus", { state: { employeeId: employeeId } });
  };


  const fetchEmployee = async () => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(apiList.profile, {
        headers: {
          token: token,
        },
      });
      setLoginEmployee(response.data.employeeName);
    } catch (error) {
      // Handle the error appropriately
    }
  };

  const traineesList = async () => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(`${apiList.traineesList}`, {
        headers: {
          token: token,
        },
      });
      setTraineesList(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployee(); // Fetch employee first
  }, []);

  useEffect(() => {
    if (loginEmployee) {
      // Only call traineesList if loginEmployee is set
      traineesList();
    }
  }, [loginEmployee]); // Add loginEmployee as a dependency



  function formatJoiningDate(joiningDate) {
    const date = new Date(joiningDate);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredTrainees = traineesArray.filter((employee) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const nameIncludesQuery = employee.employeeName.toLowerCase().includes(lowerCaseQuery);
    const idIncludesQuery = employee.employeeId.toLowerCase().includes(lowerCaseQuery);
    return nameIncludesQuery || idIncludesQuery;
  });
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3" style={{ backgroundColor: "rgb(115 50 114)" }}>
            <TrainerSidebar />
          </div>
          <div
            className="col-lg-9 trainer_dashboard element"
            style={{ backgroundColor: "#ffffff", height: "100vh", overflowY: "scroll", paddingTop: "25px" }}
          >
            <h1 className=" heads" style={{ color: "#6a2a69" }}>Trainee Feedback's</h1>
            <h5 className='mb-3'>
              Welcome to the Perfex Trainer Feedback Dashboard. Please search Trainee FeedBack
            </h5>

            <div className="search-bar row ">
              <div className='col-md-8 mb-2'>
                <input
                  type="text"
                  placeholder="Search by employee name or employee ID"
                  value={searchQuery}
                  onChange={handleSearch}
                  className='w-100 p-2 employee_filter_searchBar'
                />
              </div>
            </div>

            {loading ? ( // Conditionally render loader based on loading state
              <div className="d-flex justify-content-center align-items-center mt-5">
                <Audio
                  type="Audio"
                  color="#6a2a69"
                  height={50}
                  width={60}
                />
                
              </div>
            ) : (
              <table className="table table-bordered text-center table-hover admin_table">
                <thead className="table_head">
                  <tr>
                    <th className='table_head1'>SNo</th>
                    <th className='table_head1'>Employee Name</th>
                    <th className='table_head1'>Email</th>
                    <th className='table_head1'>Employee ID</th>
                    <th className='table_head1'>View</th>
                  </tr>
                </thead>
                <tbody className="table_body">
                  {filteredTrainees.map((employee, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{employee.employeeName}</td>
                      <td>{employee.email}</td>
                      <td>{employee.employeeId}</td>
                      <td>
                        <a className="" onClick={() => GotoFeedback(employee.employeeId)}>Feedback</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineesList;

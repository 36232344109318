import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import Sidebar from '../TrainerSidebar';
import apiList from '../../lib/apiList';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Audio } from 'react-loader-spinner';

function EmployeFeedback() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { employeeId } = state;
  const [employeeWorksheet, setEmployeeWorksheet] = useState([]);
  const [weekworksheets, setWeekWorksheet] = useState([]);
  const [selectedWeekData, setSelectedWeekData] = useState(null);
  const [visibleWeeks, setVisibleWeeks] = useState(4);
  const pageSize = 4;
  const [loading, setLoading] = useState(true);

  const handleViewClick = (weeks) => {
    setSelectedWeekData(weeks);
  };

  const loadMoreWeeks = () => {
    setVisibleWeeks((prevVisibleWeeks) => prevVisibleWeeks + pageSize);
  };

  const onBackToTraineeList = () => {
    window.location.href = "traineeslist";
  };

  useEffect(() => {
    let token = Cookies.get('token');
    if (token === undefined) {
      navigate('/');
    }
    review();
  }, []);

  const review = async () => {
    const token = Cookies.get("token");
    try {
      const api = `${apiList.indEmployeWorkSheet}/${employeeId}`;
      const response = await axios.get(api, {
        headers: {
          token: token,
        },
      });
      setEmployeeWorksheet(response.data);
      setWeekWorksheet(response.data.weekperiod);
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      setLoading(false); // Set loading to false on error
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-3  sidebar_bg'>
            <Sidebar />
          </div>
          <div className='col-lg-9' style={{ height: "100vh", overflow: "scroll" }}>
            <button className="back-arrow" onClick={onBackToTraineeList}>
              <IoIosArrowBack />
            </button>
            <h3 className="admin_header1" style={{ color: "#6a2a69" }}>
              Employee Name: <span>{employeeWorksheet.employeeName}</span>
            </h3>
            <h5 className="adimn_dashnbord1">
              Employee ID: <span>{employeeWorksheet.employeeId}</span>
            </h5>
            <div className='month_item'>
              <div className='month'>Submitted Reports</div>
            </div>
            <div>
              <div className=''>
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center mt-5">
                    <Audio
                      type="Audio"
                      color="#6a2a69"
                      height={50}
                      width={60}
                    />

                  </div>
                ) : (
                  <>
                    {weekworksheets.length === 0 ? (
                      <div className='text-center'>
                        <img src="./images/no_feedback.png" alt="no  feedback" style={{ height: "400px" }} />
                      </div>
                    ) : (
                      weekworksheets.slice(0, visibleWeeks).map((weeks, index) => (
                        <div className="card my-3" key={index}>
                          <div className="week_item"> {weeks.startDate} to {weeks.endDate} </div>
                          <div className='my-2'>
                            <div className='d-flex justify-content-between'>
                              <div>
                                <div className='status'>
                                  Status:
                                  {weeks.trainerName ? (
                                    <img src="https://res.cloudinary.com/dhpkv1tec/image/upload/v1699716148/natural%20places/green_tick-removebg-preview_hwk5gj.png" alt="Done" style={{ width: "25px" }} />
                                  ) : (
                                    <img src="https://res.cloudinary.com/dhpkv1tec/image/upload/v1699716461/natural%20places/red_cross-removebg-preview_pe0azt.png" alt="Pending" style={{ width: "25px" }} />
                                  )}
                                </div>
                              </div>
                              <div
                                className="view_button"
                                data-toggle="modal"
                                data-target="#myModal"
                                onClick={() => handleViewClick(weeks)}
                              >
                                View
                              </div>
                            </div>
                          </div>
                          <div className="modal" id="myModal">
                            <div className="modal-dialog modal-lg">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h4 className="modal-title"> FeedBack</h4>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    style={{
                                      marginTop: "-34px",
                                      marginRight: "-36px",
                                      backgroundColor: "rgb(255 255 255)",
                                      borderRadius: "50px",
                                      padding: "8px 13px 8px",
                                      fontSize: "25px"
                                    }}
                                  >
                                    &times;
                                  </button>
                                </div>
                                <div className="modal-body">
                                  {selectedWeekData && (
                                    <>
                                      <div className="week_item">
                                        {" "}
                                        Week :[{selectedWeekData.startDate} to {selectedWeekData.endDate}]
                                      </div>
                                      <div>
                                        {Object.keys(selectedWeekData?.worksheets ?? []).map((v) => (
                                          <>
                                            <div className="adimn_dashnbord1 pt-3 pb-1" style={{ fontWeight: "500" }}>
                                              <div>{v}</div>
                                            </div>
                                            <div className=" text_area" style={{ height: "200px", overflowY: "scroll", padding: "7px", textAlign: "justify" }}>
                                              {selectedWeekData.worksheets[v]}
                                            </div>
                                          </>
                                        ))}
                                        <div className="adimn_dashnbord1 pt-3 pb-1 " style={{ fontWeight: "500", textAlign: "justify" }}>
                                          {" "}
                                          Feedback by Employee
                                        </div>
                                        <div className=" text_area p-2" style={{ height: "200px", overflowY: "scroll" }}>
                                          <p>Trainer for the week - {selectedWeekData.trainerName} </p>
                                          {selectedWeekData.feedbackByEmployeeforTrainer}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="table_button1"
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                    {visibleWeeks < weekworksheets.length && (
                      <div className=" d-flex justify-content-end">
                        <h6 className="" onClick={loadMoreWeeks} style={{ cursor: "pointer" }}>
                          Load More..
                        </h6>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeFeedback;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./sidebar";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import apiList from "../lib/apiList";


function formatDate(dateString) {
	const date = new Date(dateString);
	const day = date.getDate();
	const month = date.getMonth() + 1; // Month is 0-indexed, so add 1
	const year = date.getFullYear();

	// Use template literals to format the date in "DD-MM-YYYY" format
	return `${day < 10 ? "0" : ""}${day}-${month < 10 ? "0" : ""
		}${month}-${year}`;
}

function Holiday() {
	const [holidayDate, setHolidayDate] = useState("");
	const [holidayDes, setHolidayDes] = useState("");
	const [updatedHolidayDes, setUpdatedHolidayDes] = useState("");
	const [updatedHolidayWish, setUpdatedHolidayWish] = useState("");
	const [holidays, setHolidays] = useState([]);
	const [holidayWish, setHolidayWish] = useState("");
	const [showAllHolidays, setShowAllHolidays] = useState(false);

	// State to track the holiday wish for the current date
	const [currentDateHolidayWish, setCurrentDateHolidayWish] = useState("");

	useEffect(() => {
		// Load holidays from the backend when the component mounts.
		loadHolidays();
	}, []);

	const loadHolidays = async () => {
		try {
			const response = await axios.get(`${apiList.postHoliday}`);
			console.log("Holidays from server:", response.data);
			const sortedHolidays = response.data.sort((a, b) => {
				const dateA = new Date(a.date);
				const dateB = new Date(b.date);
				return dateA - dateB;
			});
			setHolidays(sortedHolidays);
		} catch (error) {
			console.error("Error loading holidays:", error);
		}
	};

	const setHoliday = async () => {
		if (!holidayDate) {
			alert("Please select a date.");
			return; // Don't proceed if no date is selected
		}

		if (!holidayDes) {
			alert("Please add description.");
			return; // Don't proceed if no date is selected
		}

		if (!holidayWish) {
			alert("Please give wish.");
			return; // Don't proceed if no date is selected
		}


		try {
			await axios.post(`${apiList.postHoliday}`, {
				date: holidayDate,
				holidayDes,
				holidayWish,
			});
			loadHolidays();

			// Clear the input fields
			setHolidayDate("");
			setHolidayDes("");
			setHolidayWish("");

			toast("Holiday Added Successfull", {
				position: "top-right",
				autoClose: 3000, // Adjust the auto-close duration as needed
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
				className: "custom-toast-custom"
			});
		} catch (error) {
			console.error("Error setting holiday:", error);
		}
	};
	const updateHolidayDesAndWish = async () => {
		if (!holidayDate) {
			alert("Please select a date.");
			return; // Don't proceed if no date is selected
		}

		try {
			const requestData = {
				date: holidayDate,
				newHolidayDes: updatedHolidayDes,
				newHolidayWish: updatedHolidayWish,
			};
			await axios.put(`${apiList.postHoliday}`, requestData);
			loadHolidays();

			// Clear the input fields
			setUpdatedHolidayDes("");
			setUpdatedHolidayWish("");
			setHolidayDate("");
		} catch (error) {
			console.error("Error updating holiday description and wish:", error);
		}
	};

	const removeHoliday = async () => {
		if (!holidayDate) {
			alert("Please select a date.");
			return; // Don't proceed if no date is selected
		}

		try {
			const requestData = { date: holidayDate, holidayDes, holidayWish };
			await axios.delete(`${apiList.postHoliday}`, {
				data: requestData,
			});
			loadHolidays();

			setHolidayDate("");

			toast("Holiday Removed Successfull", {
				position: "top-right",
				autoClose: 3000, // Adjust the auto-close duration as needed
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
				className: "custom-toast-custom"
			});
		} catch (error) {
			console.error("Error removing holiday:", error);
		}
	};

	const handleDateChange = (event) => {
		setHolidayDate(event.target.value);
	};

	const handleHolidayDesChange = (event) => {
		setHolidayDes(event.target.value);
	};

	const handleHoliayWishChange = (event) => {
		setHolidayWish(event.target.value);
	};
	const handleUpdatedHolidayDesChange = (event) => {
		setUpdatedHolidayDes(event.target.value);
	};
	const handleUpdatedHolidayWishChange = (event) => {
		setUpdatedHolidayWish(event.target.value);
	};

	const isHoliday = (date) => {
		return holidays.some((holiday) => formatDate(holiday.date) === date);
	};

	const isCurrentDateHoliday = () => {
		return holidays.some(
			(holiday) => formatDate(holiday.date) === formattedToday
		);
	};

	const today = new Date();
	const formattedToday = formatDate(today);
	const isTodayHoliday = isHoliday(formattedToday);

	const nearestHoliday = holidays
		.filter((holiday) => new Date(holiday.date) >= today)
		.sort((a, b) => new Date(a.date) - new Date(b.date))[0];

	const todayHoliday = holidays.find(
		(holiday) => formatDate(holiday.date) === formattedToday
	);

	const redTextStyle = {
		color: "red",
		fontWeight: "700",
		fontFamily: "sans-serif",
		fontSize: "22px",
	};

	useEffect(() => {
		// Check if the current date is a holiday and has a holiday wish
		if (isTodayHoliday && todayHoliday) {
			setCurrentDateHolidayWish(todayHoliday.holidayWish);
		} else {
			// Reset the holiday wish if it's not a holiday
			setCurrentDateHolidayWish("");
		}
	}, [isTodayHoliday, todayHoliday]);

	return (
		<div className="container-fluid " style={{ overflow: "hidden" }}>
			<div className="row">
				<div className="col-md-3 sidebar_bg " style={{ height: "100vh" }}>
					<Sidebar />
					<ToastContainer />
				</div>

				<div
					className="col-md-9 holiday_sec2 p-0"
					style={{ height: "100vh", overflowY: "scroll" }}
				>
					<div className="holiday_head">
						<h1>
							<em>Add Holiday</em>
						</h1>
					</div>

					<div className="container">
						<div className="mt-4">
							<input
								type="date"
								placeholder="add holiday date"
								value={holidayDate}
								onChange={handleDateChange}
								className="Admin_date p-2"
								
							/>
						</div>
					</div>

					<div className="container">
						<div className="row mt-4">
							<div className="col-md-12">
								<input
									type="text"
									placeholder="Holiday Description"
									value={holidayDes}
									onChange={handleHolidayDesChange}
									className="Admin_date_i p-3 form-control"
								/>
							</div>
						</div>

						<div className="row mt-4">
							<div className="col-md-12">
								<input
									type="text"
									placeholder="Holiday Wish"
									value={holidayWish}
									onChange={handleHoliayWishChange}
									className="Admin_date_i p-3 form-control"
								/>
							</div>
						</div>
					</div>

					<div className="container mt-3">
						<div className="">
							<button onClick={setHoliday} className="p-2 Admin_date1">
								Set Holiday
							</button>
							<button onClick={removeHoliday} className="p-2 Admin_date1">
								Remove Holiday
							</button>
						</div>
					</div>

					<div className="container">
						<div className="mt-3">
							<input
								type="text"
								placeholder="Update Holiday Description"
								value={updatedHolidayDes}
								onChange={handleUpdatedHolidayDesChange}
								className="Admin_date_i p-3 form-control"
							/>
						</div>
					</div>

					<div className="container">
						<div className="mt-3">
							<input
								type="text"
								placeholder="Update Holiday Wish"
								value={updatedHolidayWish}
								onChange={handleUpdatedHolidayWishChange}
								className="Admin_date_i p-3 form-control"
							/>
						</div>
					</div>

					<div className="container mt-2">
						<button
							onClick={updateHolidayDesAndWish}
							className="p-2 Admin_date2 mt-2"
							style={{ width: "35%" }}
						>
							Update Holiday Description and Wish
						</button>
					</div>

					<div className="calendar mt-3 text-center">
						<h2 className="holiday_head">
							<em style={{ color: "#6a2a69", fontWeight:"600" }}>Holidays </em>
						</h2>

						{showAllHolidays ? (
							<div style={{ height: "150px", overflowY: "scroll", width: "700px", margin: "auto" }}>
								<table className=" text-center table-bordered  table-hover w-100  m-auto">
									<thead className="table_heading sticky-top">
										<tr style={{ color: "#fff" }}>
											<th className="p-2">Date</th>
											<th className="p-2">Description</th>
										</tr>
									</thead>
									<tbody>
										{holidays.map((holiday) => (
											<tr key={holiday._id}>
												<td className="p-1" style={{ fontSize: "18px", fontWeight: "400" }}>{formatDate(holiday.date)}</td>
												<td className="p-1" style={{ fontSize: "18px", fontWeight: "400" }}>{holiday.holidayDes}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<>
								{isTodayHoliday ? (
									<div>
										<em className='holidayWishes holidaysss' >
											{formattedToday} -{" "}
											{todayHoliday
												? todayHoliday.holidayDes
												: "No description"}{" "}
											(Today is a holiday!)
										</em>
									</div>
								) : (
									nearestHoliday && (
										<div>
											<p
												style={{  color: "#fff" }}
												className="holidaysss"
											>
												Upcoming Holiday : {formatDate(nearestHoliday.date)} -{" "}
												{nearestHoliday.holidayDes}
											</p>
										</div>
									)
								)}
							</>
						)}

						{isTodayHoliday && currentDateHolidayWish && (
							<div className="p-3">
								<em className="holidayWishes">{currentDateHolidayWish}</em>
							</div>
						)}

						{!showAllHolidays ? (
							<button
								onClick={() => setShowAllHolidays(true)}
								className="p-2 update_holiday mb-3 mt-2"
							>
								View All Holidays
							</button>
						) : (
							<button
								onClick={() => setShowAllHolidays(false)}
								className="p-2 update_holiday mb-3 mt-2"
							>
								Hide Holidays
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Holiday;

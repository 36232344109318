import React from 'react'
import TrainerSidebar from '../TrainerSidebar'
import { Link } from 'react-router-dom'
const TrinessList1 = () => {
  return (
    <div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-3' style={{ backgroundColor: "#733272" }}>
            <TrainerSidebar />
          </div>
          <div
            className="col-lg-9 trainer_dashboard"
            style={{ backgroundColor: "#ffffff", overflowX: "scroll", height: "100vh", overflowY: "scroll" }}
          >
            <h1 className='heads'>All FeedBack For Trainee's Given as a Trainer</h1>
            <h6>
              Welcome to the Perfex Trainer Dashboard . Please Search
              Employement feedback.
            </h6>

            <table className="table table-bordered table-hover mt-3">
              <tr style={{ backgroundColor: "#6a2a69", color: "#ffffff" }}>
                <th>SNo</th>
                <th>EmployeeName</th>
                <th>Email</th>
                <th>EmployeeId</th>
                <th>MobileNumber</th>
                <th>JoingDate</th>
                <th>View</th>
              </tr>

              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <Link to="/trainerfeedback1" className='table_links'>view</Link>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <Link to="/trainerfeedback1">view</Link>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>

              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>AshokKumar</td>
                <td>ramisettyashokkumar@perfextechnologies.com</td>
                <td>PX0964</td>
                <td>9505209760</td>
                <td>01/09/2023</td>
                <td>
                  <a href="">Feedback</a>
                </td>
              </tr>

            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrinessList1

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import apiList from '../lib/apiList';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Audio } from 'react-loader-spinner';


const Login = () => {
  let navigate = useNavigate();
  const [employeeId, setEmployeeId] = useState('');
  const [password, setPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [audioVisible, setAudioVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const GotoForgot = () => {
    navigate('/Forgotpassword');
  }

  useEffect(() => {
    let token = Cookies.get('token');

    if (token !== undefined) {
      let empType = localStorage.getItem('employeType');
      if (empType === 'Admin') {
        navigate('/admin');
      }
      if (empType === 'Trainer') {
        navigate('/trainer');
      }
      if (empType === 'Trainee') {
        navigate('/trainee');
      }
    }
  }, []);

  const onSubmitBtn = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      loginUser();
    }, 4000); // Display loader for 2 seconds before initiating login
  };

  const loginUser = () => {
    const userData = {
      employeeId: employeeId,
      password: password,
    };

    if (employeeId && password !== "") {
      axios
        .post(`${apiList.login}`, userData)
        .then((response) => {
          if (response.status === 200) {
            let jwtToken = response.data.token;
            let empType = response.data.empType;
            let loggedInTime = response.data.currentTime;

            localStorage.setItem("employeType", empType);
            localStorage.setItem("loggedinTime", loggedInTime);
            const expirationDate = new Date();
            expirationDate.setTime(expirationDate.getTime() + 10 * 60 * 60 * 1000);
            Cookies.set('token', jwtToken, { expires: expirationDate });

            if (empType === "Admin") {
              navigate("/holiday");
            }
            if (empType === 'Trainer') {
              navigate("/Trainerholiday");
            }
            if (empType === 'Trainee') {
              navigate('/Traineeholiday');
            }
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setLoading(false);
          setAudioVisible(true);
        });
    } else {
      toast.warning("Please enter required details");
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      loginUser();
    }
  };

  return (
    <div className="fixed-top">
      <ToastContainer />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 p-0">
            <div>
              <img
                src="./images/login banner2.png"
                className="perfex_img"
                alt="banner"
                style={{ height: "100vh", width: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-1" style={{ backgroundColor: "#fff" }}></div>
          <div className="col-md-4 side my-auto">
            {loading && !audioVisible && (
              <div className="text-center"><Audio
                height={80}
                width={80}
                radius={9}
                color="#6a2a69"
                ariaLabel="loading"
                wrapperStyle={{ display: "inline-block" }}
                wrapperClass="loader-audio"
              /></div>

            )}

            {!loading || audioVisible ? (
              <div>
                <h3 className="mb-2" style={{ fontWeight: "700", color: "#6a2a69" }}>
                  Hi,&#128075;
                </h3>
                <h5 className="mb-4 h5_class" style={{ fontWeight: "500" }}>
                  Welcome to Perfex Technology Dashboard
                </h5>

                <form onSubmit={onSubmitBtn}>
                  <div className="my-3">
                    <label>Employee Id :</label>
                    <input
                      type="text"
                      className="form-control mb-3 p-4"
                      placeholder="Enter Your Employee Id"
                      value={employeeId}
                      onChange={(e) => setEmployeeId(e.target.value)}
                    />
                    <label>Password:</label>
                    <div className="flex-item">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="psw"
                        className="form-control input_password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyPress={handleKeyPress}
                      />
                      {showPassword ? (
                        <i
                          className="fa-solid fa-eye p-3"
                          onClick={togglePasswordVisibility}
                        ></i>
                      ) : (
                        <i
                          className="fa-solid fa-eye-slash p-3"
                          onClick={togglePasswordVisibility}
                        ></i>
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <button type="submit" className="login_button p-2">
                      Login
                    </button>
                  </div>
                </form>
              </div>
            ) : null}
            {/* <div className="text-center">
              <span className="forgot" onClick={GotoForgot}>
                Forgot Password
              </span>
            </div> */}
          </div>
          <div className="col-md-1" style={{ backgroundColor: "#fff" }}></div>
        </div>
      </div>
    </div>
  );
};

export default Login;

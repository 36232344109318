import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import apiList from "../lib/apiList";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "./sidebar";
import Cookies from "js-cookie";

function Admin() {
  const navigate = useNavigate();
  const [employeeName, setEmployeeName] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [empType, setEmptype] = useState("");
  const [phone, setPhone] = useState("");
  const [designation, setDesignation] = useState("");
  const [adhar, setAdhar] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [trainingCourse, setTrainingCourse] = useState("");
  const [trainerName, setTrainerName] = useState("");
  const [selectTrainer, setTrainer] = useState("");

  const userData = {
    employeeName: employeeName,
    employeeId: employeeId,
    email: email,
    password: password,
    employeeType: empType,
    phoneNumber: phone,
    designation: designation,
    aadharNumber: adhar,
    joiningDate: joiningDate,
    trainingCourse: trainingCourse,
    trainerName: selectTrainer,
  };

  const eData = {
    employeeName: employeeName,
    employeeId: employeeId,
    email: email,
    employeeType: empType,
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    const token = Cookies.get("token");

    if (employeeName && email && phone && password !== "") {
      // setTypeOfRegistration(name)
      axios
        .post(`${apiList.addEmployee}`, userData, {
          headers: {
            token: token,
          },
        })
        .then((response) => {
          // setData(response.data)
          if (response.status === 200) {
            toast("Employee Added", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              className: "custom-toast-custom",
            });
            setEmployeeName("");
            setEmployeeId("");
            setEmail("");
            setpassword("");
            setPhone("");
            setDesignation("");
            setJoiningDate("");
            setAdhar("");
            setEmptype("");
            setTrainerName("");
            setTrainingCourse("");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          toast.error("employee already added");
        });

      axios
        .post(`${apiList.addEmployeWorkSheet}`, eData, {
          headers: {
            token: token,
          },
        })
        .then((response) => {
          // setData(response.data)
          if (response.status === 200) {
            console.log("employee added successfully");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    } else {
      toast.warning("Enter Required details");
    }
  };

  useEffect(() => {
    let token = Cookies.get("token");
    console.log(token);
    if (token === undefined) {
      navigate("/");
    }
  }, []);

  const handleTrainerSelection = (event) => {
    setTrainer(
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-value"
      )
    );
  };

  const [employees, setEmployees] = useState([]);

  const fetchApi = async () => {
    let token = Cookies.get("token");
    try {
      const response = await axios.get(`${apiList.getTrainersList}`, {
        headers: {
          token: token,
        },
      });
      setEmployees(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchApi();
  }, [employees]);

  console.log(employees);

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-md-3 sticky-top  sidebar_bg"
          style={{ height: "100vh" }}
        >
          <ToastContainer />
          <Sidebar />
        </div>
        <div className="col-md-9  d-lg-block d-xl-block d-none">
          <div className="form_item ">
            <form>
              <div className="admin_header ">Admin Dashboard</div>
              <div className="adimn_dashnbord ">
                Welcome to the Perfex Admin Dashboard. Please Add your
                Employement.
              </div>
              <div className="row mb-1">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-1">
                    <label>Employee Name <sup className="star">*</sup></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Employee Name"
                      value={employeeName}
                      onChange={(e) => setEmployeeName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-1">
                    <label>Employee Id <sup className="star">*</sup></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Employee Id"
                      value={employeeId}
                      onChange={(e) => setEmployeeId(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-1">
                    <label>Email <sup className="star">*</sup></label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Employee Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-1">
                    <label>Password <sup className="star">*</sup></label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Employee Password"
                      value={password}
                      onChange={(e) => setpassword(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-1">
                    <label>Phone Number <sup className="star">*</sup></label>
                    <input
                      type="Number"
                      className="form-control"
                      placeholder="Employee Phone Number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-1">
                    <label>Designation <sup className="star">*</sup></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Designation"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-1">
                    <label>Joining Date <sup className="star">*</sup></label>
                    <input
                      type="date"
                      className="form-control"
                      value={joiningDate}
                      onChange={(e) => setJoiningDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-1">
                    <label>Aadhar Number <sup className="star">*</sup></label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Your Aadhar Number"
                      value={adhar}
                      onChange={(e) => setAdhar(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-1">
                <label>Employee Type <sup className="star">*</sup></label>
              </div>
              <div>
                <input
                  type="radio"
                  name="empType"
                  value="Trainer"
                  onChange={(e) => setEmptype(e.target.value)}
                />{" "}
                <label className="mx-2">Trainer</label>
                <input
                  type="radio"
                  name="empType"
                  value="Trainee"
                  onChange={(e) => setEmptype(e.target.value)}
                />
                <label className="mx-2">Trainee</label>
              </div>
              <div className="row mb-1">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-1">
                    <label>Training Course <sup className="star">*</sup></label>
                    <input
                      type="text"
                      className="form-control disableInput"
                      placeholder="Enter Your Course"
                      value={trainingCourse}
                      onChange={(e) => setTrainingCourse(e.target.value)}
                      disabled={empType === "Trainer"}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-1">
                    <label>Trainer Name <sup className="star">*</sup></label>
                    <select
                      className="w-100 select_item "
                      onChange={handleTrainerSelection}
                      disabled={empType === "Trainer"}
                    >

                      <option className="hidden" value="nb-1">
                        Select Trainer
                      </option>
                      {employees.map((employee) => (
                        <option
                          className="name_item"
                          key={employee._id} // Use a unique key for each option
                          data-value={employee.employeeName}
                          value={selectTrainer || ""}
                        >
                          {employee.employeeName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="employement_button my-3">
                <button className="employement_item" onClick={onSubmitForm}>
                  Add Employee
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import TrainerSidebar from "./TrainerSidebar";
import axios from "axios";
import Cookies from "js-cookie";
import apiList from "../lib/apiList";
import { ToastContainer, toast } from "react-toastify";
import { Audio } from 'react-loader-spinner';

const Trainer = () => {
  const navigate = useNavigate();
  const [loginEmployee, setLoginEmployee] = useState("");
  const [traineesArray, setTraineesList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [punchInTime, setPunchInTime] = useState(null);
  const [running, setRunning] = useState(null);
  const [punchOutTime, setPunchOutTime] = useState(null);
  const [duration, setDuration] = useState(null);
  const [time, setTime] = useState(null);
  const [totalDuration, setTotalDuration] = useState(null);
  const [previousDuration, setPreviousDuration] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token === undefined) {
      navigate("/");
    }
  }, []);

  const GotoGiveFeedback = (employeeId) => {
    navigate("/trainerreview", { state: { employeeId: employeeId } });
  };

  const GotoViewFeedback = (employeeId) => {
    navigate("/trainerfeedback1", { state: { employeeId: employeeId } });
  };

  const fetchEmployee = async () => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(apiList.profile, {
        headers: {
          token: token,
        },
      });
      setLoginEmployee(response.data.employeeName);

    } catch (error) {
      // Handle the error appropriately
    }
  };

  const traineesList = async () => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(apiList.traineesList, {
        headers: {
          token: token,
        },
      });
      setTraineesList(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  const handlePunchIn = () => {
    const punchInTimestamp = new Date();
    setTime(new Date());
    const expirationTime = new Date(punchInTimestamp.getTime() + 10 * 60 * 60 * 1000);
    setPunchInTime(punchInTimestamp);
    Cookies.set("punchInTime", punchInTimestamp, { expires: expirationTime });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRunning(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const punchInTime = Cookies.get("punchInTime");
    if (punchInTime) {
      setPunchInTime(punchInTime);
    }
  }, []);

  useEffect(() => {
    if (loginEmployee) {
      traineesList();
    }
  }, [loginEmployee]);

  useEffect(() => {
    if (punchInTime) {
      const timeDifference = running - new Date(Cookies.get("punchInTime"));
      const hours = String(Math.floor(timeDifference / 3600000)).padStart(2, "0");
      const minutes = String(Math.floor((timeDifference % 3600000) / 60000)).padStart(2, "0");
      const seconds = String(Math.floor((timeDifference % 60000) / 1000)).padStart(2, "0");
      const formattedDuration = `${hours}:${minutes}:${seconds}`;
      setDuration(formattedDuration);
    }
  }, [running, punchInTime]);

  const fetchEmployeeDuration = async () => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(apiList.worksheetProfile, {
        headers: {
          token: token,
        },
      });
      setPreviousDuration(response.data.workingHours);
    } catch (error) {
      // Handle the error appropriately
    }
  };

  useEffect(() => {
    fetchEmployeeDuration();
  }, []);

  const updateEmployeeFeedback = async () => {
    const punchIn = new Date(punchInTime).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const punchOut = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    Cookies.remove("punchInTime");
    setPunchInTime(null);

    const timeDifference = new Date() - new Date(time);
    const hours = String(Math.floor(timeDifference / 3600000)).padStart(2, "0");
    const minutes = String(Math.floor((timeDifference % 3600000) / 60000)).padStart(2, "0");
    const seconds = String(Math.floor((timeDifference % 60000) / 1000)).padStart(2, "0");

    const formattedDuration = `${hours}:${minutes}:${seconds}`;
    setTotalDuration(formattedDuration);

    const token = Cookies.get("token");
    const api = apiList.workingDuration;

    try {
      const currentDate = new Date();
      const formattedDate = currentDate
        .toLocaleDateString("en-US", {
          year: "numeric",
          day: "2-digit",
          month: "2-digit",
        })
        .split("/")
        .join("-");

      const response = await axios.put(
        api,
        {
          workingHours: [
            ...previousDuration,
            {
              date: formattedDate,
              punchInTime: punchIn,
              punchOutTime: punchOut,
              duration: formattedDuration,
            },
          ],
        },
        {
          headers: {
            token: token,
          },
        }
      );

      toast("Punched Out successfully", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: "custom-toast-custom",
      });
    } catch (error) {
      // Handle the error appropriately
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredTrainees = traineesArray.filter((employee) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const nameIncludesQuery = employee.employeeName.toLowerCase().includes(lowerCaseQuery);
    const idIncludesQuery = employee.employeeId.toLowerCase().includes(lowerCaseQuery);
    return nameIncludesQuery || idIncludesQuery;
  });

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3" style={{ backgroundColor: "rgb(115 50 114)" }}>
            <TrainerSidebar />
            <ToastContainer />
          </div>

          <div
            className="col-lg-9 trainer_dashboard element"
            style={{
              backgroundColor: "#ffffff",
              height: "100vh",
              overflowY: "scroll",
            }}
          >
            <h1 className="heads" style={{ color: "#6a2a69", paddingTop: "25px" }}>
              Trainer feedback for trainee
            </h1>
            <h6 className="head1">
              Welcome to the Perfex Trainer Feedback Dashboard. Please submit Trainee's feedback.
            </h6>

            <div className="search-bar row ">
              <div className='col-md-8 mb-2'>
                <input
                  type="text"
                  placeholder="Search by employee name or employee ID"
                  value={searchQuery}
                  onChange={handleSearch}
                  className='w-100 p-2 employee_filter_searchBar'
                />
              </div>
            </div>

            {loading ? ( // Conditionally render loader based on loading state
              <div className="d-flex justify-content-center align-items-center mt-5">
                <Audio
                  type="Audio"
                  color="#6a2a69"
                  height={50}
                  width={60}
                />
                
              </div>
            ) : (
              <table className="table table-bordered text-center table-hover admin_table">
                <thead className="table_head">
                  <tr>
                    <th className="table_head1">SNo</th>
                    <th className="table_head1">Emp Name</th>
                    <th className="table_head1">Email</th>
                    <th className="table_head1">Emp ID</th>
                    <th className="table_head1">Give Feedback</th>
                    <th className="table_head1">Previous Feedback</th>
                  </tr>
                </thead>
                <tbody className="table_body">
                  {filteredTrainees.map((employee, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{employee.employeeName}</td>
                      <td>{employee.email}</td>
                      <td>{employee.employeeId}</td>
                      <td>
                        <a className="" onClick={() => GotoGiveFeedback(employee.employeeId)}>
                          Feedback
                        </a>
                      </td>
                      <td>
                        <a className="" onClick={() => GotoViewFeedback(employee.employeeId)}>
                          View
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trainer;

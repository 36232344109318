import React from 'react'
import { FiEyeOff, FiEye } from 'react-icons/fi'
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import apiList from '../lib/apiList';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';




function Forgotpassword() {

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false); 
  const [employees, setEmployees] = useState([]);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("")

  const navigate = useNavigate();  

  const { state } = useLocation();
  const { employeeId } = state;
  console.log(employeeId);
  useEffect(() => {
    let token = Cookies.get('token')
    if (token === undefined) {
      navigate('/')
    }

  }, [])


  const token = Cookies.get('token');
  const fetchApi = async () => {
    
    try {
      const response = await axios.get(`${apiList.profile}/${employeeId}`, {
        headers: {
          "token": token,
        }
      });
      setEmployees(response.data);

    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  console.log(employees);

  useEffect(()=>{
    if(employeeId)
    {
      fetchApi();
    }
  },[])


  const changePassword = async (req, res) => {
    try {
      const response = await axios.put(
        `${apiList.changepassword}/${employeeId}`,
        {
          newPassword : newPassword,
          confirmPassword : confirmPassword
        },
        {
          headers: {
            token: token,
          },
        }
      );

      if (response.status === 200) {
        // Handle a successful response
        console.log("Feedback updated successfully:", response.data);
        toast.success('password updated successfully', {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          className: '',
        });

        setTimeout(() => {
          navigate("/allEmploye")
        }, 2000);
      } 
     
    }
    
    catch (error) {
      toast.error("password updating failed",  {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: 'custom-toast-error-custom',
      });
      console.error("Error updating feedback:", error);
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  // const [password, setPassword] = useState('');
  // const [password1, setPassword1] = useState('');

  // const [showPassword, setShowPassword] = useState(false);
  // const [showPassword1, setShowPassword1] = useState(false);

  // const onShowPaaword = () => {
  //     setShowPassword(!showPassword)
  // }
  // const onShowPaaword1 = () => {
  //     setShowPassword1(!showPassword1)
  // }


  
  return (
    <div className="fixed-top">
      <ToastContainer />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 p-0">
            <div>
              <img
                src="./images/login banner2.png"
                className="perfex_img"
                alt="banner"
                style={{ height: "100vh", width: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-1" style={{ backgroundColor: "#fff" }}></div>
          <div className="col-md-4 side  my-auto">
            <div>
              <h3 className="mb-2">Hi ADMIN,&#128075;</h3>
              <h5 className="mb-2">You are changing password for </h5>
              <h5>Employee ID : {employees.employeeId}</h5>
              <h5 className='mb-5'>Employee Name : {employees.employeeName}</h5>

            </div>

            <div className="my-3">
              <label>New Password :</label>
              <div className="flex-item mb-3">
                <input
                  type={showPassword ? "text" : "password"}
                  id="psw"
                  className="form-control input_password"
                  placeholder="Enter your New Password"
                  value={newPassword}
                  onChange={(e)=>setNewPassword(e.target.value)}
                />
                {showPassword ? (
                  <i
                    className="fa-solid fa-eye p-3"
                    onClick={togglePasswordVisibility}
                  ></i>
                ) : (
                  <i
                    className="fa-solid fa-eye-slash p-3"
                    onClick={togglePasswordVisibility}
                  ></i>
                )}
              </div>

              <label>Confirm Password:</label>
              <div className="flex-item">
                <input
                  type={showPassword1 ? "text" : "password"}
                  id="psw"
                  className="form-control input_password"
                  placeholder="Enter your Confirm Password"
                  value={confirmPassword}
                  onChange={(e)=>setConfirmPassword(e.target.value)}
                />
                {showPassword1 ? (
                  <i
                    className="fa-solid fa-eye p-3"
                    onClick={togglePasswordVisibility1}
                  ></i>
                ) : (
                  <i
                    className="fa-solid fa-eye-slash p-3"
                    onClick={togglePasswordVisibility1}
                  ></i>
                )}
              </div>
            </div>
            <div className="mb-3">
              <button type="button" className="login_button" onClick={changePassword}>
                Update Password
              </button>
            </div>
          </div>
          <div className="col-md-1" style={{ backgroundColor: "#fff" }}></div>
        </div>
      </div>
    </div>
  )
}

export default Forgotpassword

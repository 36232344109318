import React, { useState } from 'react'
import Adminsidebar from './Adminsidebar'
import apiList from '../lib/apiList'
import { useEffect } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Audio } from 'react-loader-spinner';

function Allworksheet() {
  const [loginEmployee, setLoginEmployee] = useState("")
  const [traineeSheet, setTraineeSheet] = useState([])
  const [selectedWeekData, setSelectedWeekData] = useState(null);

  const initialRowCount = 2; // Number of rows to show initially
  const [showMore, setShowMore] = useState(false);

  const [worksheetLoading, setWorksheetLoading] = useState(true);


  const handleViewClick = (weeks) => {
    setSelectedWeekData(weeks);
  };

  const fetchEmployee = async (req, res) => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(`${apiList.profile}`, {
        headers: {
          token: token,
        },
      });
      setLoginEmployee(response.data);
    } catch (error) { }
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  const fetchEmployeeWorksheet = async (req, res) => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(`${apiList.worksheetProfile}`, {
        headers: {
          token: token,
        },
      });
      setTraineeSheet(response.data.weekperiod);
      console.log(response.data.weekperiod);
      setWorksheetLoading(false);

    } catch (error) {
      setWorksheetLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployee();
    fetchEmployeeWorksheet();
  }, []);
  // const visibleData = showMore ? traineeSheet : traineeSheet.slice(0, initialRowCount);

  console.log(traineeSheet);
  return (
    <div className='container-fluid '>
      <div className='row'>
        <div className='col-lg-3 sidebar_bg'>
          <Adminsidebar />
        </div>
        <div className='col-lg-9 d-lg-block d-xl-block d-none' style={{ height: "100vh", overflowY: "scroll" }}>
          <div className='' style={{ paddingTop: "25px" }}>
            <h3 className='admin_header1' style={{ color: "#6a2a69" }}>Hello <span>{loginEmployee.employeeName}</span></h3>
            <h5 className='adimn_dashnbord1'>Employee ID: <span>{loginEmployee.employeeId}</span></h5>
          </div>
          <div className='month_item'>
            <div className='month mb-3'>Submitted Reports</div>
          </div>
          <table className="table table-bordered table-hover admin_table">
            <thead className="table_head text-center">
              <tr>
                <th>SNo</th>
                <th>Week Range</th>
                <th>Status</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody className="table_body text-center">

              {worksheetLoading ? (
                <tr className='mx-auto' style={{textAlign:"center"}}>
                  <td colSpan="4"  >
                    <div className="d-flex justify-content-center align-items-center">
                      <Audio
                        type="Audio"
                        color="#6a2a69"
                        height={40}
                        width={60}
                      />
                    </div>
                  </td>
                </tr>
              ) : (
                traineeSheet.map((employee, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{employee.startDate} to {employee.endDate}</td>
                    {/* <td className={`${employee.trainerName ? "finish" : "pending"}`}>{`${employee.trainerName ? "Done" : "Pending"}`}</td> */}
                    <td className={`${employee.trainerName ? "finish" : "pending"}`}>
                      {employee.trainerName ? (
                        <img src="https://res.cloudinary.com/dhpkv1tec/image/upload/v1699716148/natural%20places/green_tick-removebg-preview_hwk5gj.png" alt="Done" style={{ width: "25px" }} />
                      ) : (
                        <img src="https://res.cloudinary.com/dhpkv1tec/image/upload/v1699716461/natural%20places/red_cross-removebg-preview_pe0azt.png" alt="Pending" style={{ width: "25px" }} />
                      )}
                    </td>
                    <td className="view_button text-center"
                      data-toggle="modal"
                      data-target="#myModal"
                      onClick={() => handleViewClick(employee)}>
                      View
                    </td>
                    <div className="modal" id="myModal">
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title"> FeedBack</h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              style={{
                                marginTop: "-34px",
                                marginRight: "-36px",
                                backgroundColor: "rgb(255 255 255)",
                                borderRadius: "50px",
                                padding: "8px 13px 8px",
                                fontSize: "25px"
                              }}
                            >
                              &times;
                            </button>
                          </div>
                          <div className="modal-body">
                            {selectedWeekData && (
                              <>
                                <div className="week_item1">
                                  {" "}
                                  Week :[{selectedWeekData.startDate} to {selectedWeekData.endDate}]
                                  {/* <span className="date_item">
                                        {selectedWeekData.dateRange}
                                      </span>{" "} */}
                                </div>

                                <div>
                                  {Object.keys(selectedWeekData?.worksheets ?? []).map((v) =>
                                    <>
                                      <div className="adimn_dashnbord1 pt-3 pb-1" style={{ fontWeight: "500", textAlign: "start" }}>

                                        <div>{v}</div>
                                      </div>

                                      <div className="text_area" style={{ height: "200px", overflowY: "scroll", padding: "10px", textAlign: "justify" }}>
                                        {selectedWeekData.worksheets[v]}
                                      </div>
                                    </>
                                  )}
                                  <div className="adimn_dashnbord1 pt-3 pb-1" style={{ fontWeight: "500", textAlign: "start" }}>
                                    {" "}
                                    Feedback by Employee
                                  </div>
                                  <div className="text_area p-2" style={{ height: "200px", overflowY: "scroll", textAlign: "justify" }}>
                                    <p>Trainer for the week - {selectedWeekData.trainerName} </p>
                                    {selectedWeekData.feedbackByEmployeeforTrainer}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          <div className="modal-footer">
                            <button
                              type="button"
                              className="table_button1"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {!showMore && (
            <div className='more_button'>
              <button onClick={() => setShowMore(true)} className='submit-button'>More..</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Allworksheet
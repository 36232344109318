import React from 'react'

function Logintest() {
  return (
    <div className='parent'>
      <div className='container-fluid'>

        <div className='row'>
          <div className='col-md-8 p-0'>
            <div>
              <img src="./images/Perfexlogo2.png" className='logo_img1' alt='logo' />
            </div>
            <div >
              <img src='https://dashboard.researchandranking.com/user_assets/Login/img/Left-Login-banner-img.png' className='' alt='banner' style={{ height: "100vh", width: "100%" }} />
            </div>
          </div>
          <div className='col-md-4 side '>
            <div >
              <h3 className='my-4'>Hi,&#128075;</h3>
            </div>
            <div className='text-center mb-4'>
              <h4>Welcome</h4>
              <h6>signin to your Dashboard</h6>
            </div>
            <div className='my-4'>
              <label>Employee Id :</label>
              <input type='text' className='form-control mb-3 p-3' placeholder='Enter Your Employee Id' />
              <label>Password:</label>
              <input type='password' className='form-control p-3' placeholder='Enter Your Password' />
            </div>
            <div className='mb-3'>
              <button type='button' className='login_button'>Login</button>
            </div>
            <div className='text-center'>
              <p className='forgot'>Forgot Password</p>
            </div>

          </div>
        </div>
      </div>
    </div>


  )
}

export default Logintest